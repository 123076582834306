<template>
 <div class="h-screen w-screen">
  <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800 h-full w-full flex items-center justify-center">
    <div class="mt-4 w-full h-full p-4 bg-gray-100">
      <form
        @submit.prevent="handleSubmit"
        class="w-full px-2 py-8 bg-white rounded-md shadow-md"
      >
        <h2 class="mb-8 text-2xl font-semibold">
          {{ $t("common.analytics") }}
        </h2>
      </form>
    </div>
  </div>
</div>

</template>

<script>
export default {
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
