import { createApp } from 'vue';
import DialogBox from '@/components/shared/DialogBox.vue';
import { i18n } from '@/main'; // Assuming you export the i18n instance from your main.js

const dialogService = {
  open({ okOnly, title, message,showCancel }) {
    return new Promise((resolve) => {
      const dialogContainer = document.createElement('div');
      const app = createApp(DialogBox, {
        okOnly,
        title,
        message,
        onClose: resolve,
        showCancel:showCancel
      });

      // Inject the i18n instance into the app for the dialog
      app.use(i18n);
      app.mount(dialogContainer);

      document.body.appendChild(dialogContainer);
    });
  },
};

export default dialogService;


