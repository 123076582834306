export class LinkCall {
    constructor(
      id = "",
      linkId = "",
      phoneNumber = "",
      created = new Date().toISOString(),
      updated = new Date().toISOString()
    ) {
      this.id = id;
      this.linkId = linkId;
      this.phoneNumber = phoneNumber;
      this.created = created;
      this.updated = updated;
    }
  }
  