<template>
  <div class="">
    <div class="px-4 py-6 pt-16 font-semibold text-center text-gray-800 md:py-12 lg:py-16">
      <div class="mt-6">


        <div class="hidden sm:block">
          <p class="text-4xl font-bold   ">
            {{ $t("landing.title1") }}
          </p>
          <p class="text-4xl font-bold   ">
            {{ $t("landing.title2") }}
          </p>
        </div>

        <div class="block sm:hidden">
          <div class="relative m-4 mx-auto bg-white   lg:h-1/2 lg:w-1/2">
          <img src="@/assets/img/landingPageTitle.jpg" class="p-4 mx-auto" />
        </div>
        </div>



        <div class="relative m-4 mx-auto bg-white rounded-md shadow-lg lg:h-1/2 lg:w-1/2">
          <img src="@/assets/img/landingPage1.png" class="p-4 mx-auto" />
        </div>

        <button @click="getStarted"
          class="px-4 py-2 m-2 font-semibold text-gray-700 border rounded bg-lynx-light-green border-lynx-light-blue hover:bg-lynx-light-blue hover:text-white hover:border-transparent">
          {{ $t("landing.get-started-now") }}
        </button>
      </div>

      <!-- Grid wrapper -->
      <div class="flex flex-wrap -mx-4">
        <!-- Grid column -->
        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.dynamic-links-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.dynamic-links-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'dynamic-links-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>

        <!-- Grid column -->
        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.personalize-messages-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.personalize-messages-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'messages-for-diverse-audiences-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>
        <!-- Grid column -->
        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.instant-tracking-and-monitoring-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.instant-tracking-and-monitoring-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'instant-tracking-and-monitoring-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>

        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="relative flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="absolute top-0 right-0 mt-2 mr-2 font-bold text-red-500">
              {{ $t("common.new") }}
            </div>
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.hot-spots-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.hot-spots-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'hotspots-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>

        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="relative flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="absolute top-0 right-0 mt-2 mr-2 font-bold text-red-500">
              {{ $t("common.new") }}
            </div>
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.games-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.games-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'games-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>

        <div class="flex flex-col w-full p-4 sm:w-1/2 lg:w-1/3">
          <!-- Column contents -->
          <div class="relative flex flex-col flex-1 px-10 py-12 bg-white rounded-md shadow-lg">
            <div class="absolute top-0 right-0 mt-2 mr-2 font-bold text-red-500">
              {{ $t("common.coming-soon") }}
            </div>
            <div class="flex-1">
              <h2 class="text-2xl font-bold leading-snug text-gray-800">
                {{ $t("landing.augmented-links-title") }}
              </h2>
              <p class="mt-2 text-md">
                {{ $t("landing.augmented-links-description") }}
              </p>
            </div>
            <router-link :to="{ name: 'augmented-links-description' }"
              class="inline-flex items-center px-6 py-3 mt-6 font-semibold text-white rounded-md shadow-sm bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.read-more") }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/components/firebase";
export default {
  data() {
    return {};
  },
  methods: {
    getStarted() {
      if (auth.currentUser?.uid) {
        this.$router.push("/dashboard");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style></style>
