<template>
  <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
    <div class="mt-4 w-full p-4 mx-auto bg-gray-100">
      <form
        @submit.prevent="handleSubmit"
        class="w-full px-2 py-8 bg-white rounded-md shadow-md"
      >
        <h2 class="mb-8 text-2xl font-semibold">
          {{ $t("faq.frequently-asked-questions") }}
        </h2>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
