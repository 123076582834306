<template>
  <div class=" px-4 py-8 pt-16 font-semibold text-center text-gray-800">
    <div class="mt-4 w-full p-4 mx-auto bg-gray-100">
      <form
        @submit.prevent="handleSubmit"
        class="w-full px-2 py-8 bg-white rounded-md shadow-md"
      >
        <h1 class="mb-4 text-2xl font-semibold">
          {{ $t("pricing.pricing-plans") }}
        </h1>

        <!-- Pricing Plans -->
        <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div v-for="plan in pricingPlans" :key="plan.id" class="mb-8">
            <h2 class="mb-2 text-lg font-semibold">{{ plan.name }}</h2>
            <p class="mb-4 text-gray-600">{{ plan.description }}</p>
            <p class="mb-4 text-xl font-semibold">
              £{{ plan.price }}/{{ $t("common.month") }}
            </p>
            <button
              @click="selectPlan(plan)"
              class="px-4 py-2 font-semibold text-white bg-lynx-dark-blue rounded sm:hover:bg-lynx-light-blue"
            >
              {{ $t("pricing.select-plan") }}
            </button>
          </div>
         
          <!-- Total Price -->
          <div v-if="selectedPlan || selectedAddons.length > 0" class="mt-8">
            <h2 class="mb-4 text-xl font-semibold">
              {{ $t("pricing.total-monthly-price") }}
            </h2>
            <p class="text-2xl font-semibold">
              £{{ calculateTotalPrice() }}/{{ $t("common.month") }}
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pricingPlans: [
        {
          id: 1,
          name: this.$t("pricing.free-Plans"),
          description: this.$t("pricing.limited-features"),
          price: 0,
        },
        {
          id: 2,
          name: this.$t("pricing.basic-plan"),
          description: this.$t("pricing.essential-features"),
          price: 5,
        },
        {
          id: 3,
          name: this.$t("pricing.pro-plan"),
          description: this.$t("pricing.advanced-features"),
          price: 15,
        },
        {
          id: 4,
          name: this.$t("pricing.business-plan"),
          description: this.$t("pricing.business-level-features"),
          price: 30,
        },
      ],
      selectedPlan: null,
      selectedAddons: [],
    };
  },
  methods: {
    selectPlan(plan) {
      this.selectedPlan = plan;
    },
    calculateTotalPrice() {
      let totalPrice = this.selectedPlan ? this.selectedPlan.price : 0;
      totalPrice += this.selectedAddons.reduce(
        (acc, addon) => acc + addon.price,
        0
      );
      return totalPrice;
    },
  },
};
</script>
