export class Link {
  constructor(
    id = "",
    name = "",
    group = "",
    type = "",
    url = "",
    externalUrl = "",
    urlGuid = "",
    text = "",
    customerUid = "",
    groupId = "",
    typeId = "",
    dateRangeActive = false,
    startDate = new Date().toISOString().split("T")[0], // Set to today's date
    endDate = new Date().toISOString().split("T")[0], // Set to today's date
    created = new Date().toISOString(),
    updated = new Date().toISOString(),
    scanned = 0
  ) {
    this.id = id;
    this.name = name;
    this.group = group;
    this.groupId = groupId;
    this.typeId = typeId;
    this.type = type;
    this.url = url;
    this.externalUrl = externalUrl;
    this.urlGuid = urlGuid;
    this.text = text;
    this.customerUid = customerUid;
    this.dateRangeActive = dateRangeActive;
    this.startDate = startDate;
    this.endDate = endDate;
    this.created = created;
    this.updated = updated;
    this.scanned = scanned;
  }
}
