import "firebase/compat/firestore";
import { firestore } from "../components/firebase";

const db = firestore;

export const getLinkBarCodeStyleByLinkId = async (linkId) => {
    const querySnapshot = await db.collection("link_barcode_style").where("linkId", "==", linkId).get();

    if (!querySnapshot.empty) {
        // Since there could be multiple matching documents, you might want to handle them accordingly.
        // For now, just return the first matching document.
        const docSnapshot = querySnapshot.docs[0];
        return {
            id: docSnapshot.id,
            ...docSnapshot.data(),
        };
    } else {
        throw new Error("Link Barcode Style not found");
    }
};

export const saveLinkBarCodeStyle = async (data) => {
    const linkBarCodeStyleCollection = db.collection("link_barcode_style");

    if (data.id) {
        // Update existing link_barcode_style
        await linkBarCodeStyleCollection.doc(data.id).update({
            linkId: data.linkId,
            background: data.background,
            foreground: data.foreground,
            level: data.level,
            margin: data.margin,
            render: data.render,
            logoUrl: data.logoUrl,
            borderColor: data.borderColor,
            header: data.header,
            footer: data.footer,
            borderStyle: data.borderStyle,
            borderWidth: data.borderWidth,
            updated: new Date().toISOString(),
        });
    } else {
        // Create new link_barcode_style
        await linkBarCodeStyleCollection.add({
            linkId: data.linkId,
            background: data.background,
            foreground: data.foreground,
            level: data.level,
            margin: data.margin,
            render: data.render,
            logoUrl: data.logoUrl,
            borderColor: data.borderColor,
            header: data.header,
            footer: data.footer,
            borderStyle: data.borderStyle,
            borderWidth: data.borderWidth,
            created: new Date().toISOString(),
            updated: new Date().toISOString(),
        });
    }
    return true; // Saved successfully
};

export const deleteLinkBarCodeStyleById = async (id) => {
    const docRef = db.collection("link_barcode_style").doc(id);
    const docSnapshot = await docRef.get();

    if (docSnapshot.exists) {
        await docRef.delete();
        return true; // Deleted successfully
    } else {
        throw new Error("Link Barcode Style not found");
    }
};
