<template>
  <div v-if="showSpinner" class="spinner-overlay">
    <pulse-loader
      :loading="showSpinner"
      color="#60c0c0"
      size="10px"
    ></pulse-loader>
  </div>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  components: { PulseLoader },
  props: {
    showSpinner: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Adjust the background color and opacity as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the overlay is on top of other elements */
}

/* Add additional styles if needed */
</style>
