export class LinkSms {
  constructor(
    id = "",
    linkId = "",
    phoneNumber = "",
    text = "",
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.linkId = linkId;
    this.phoneNumber = phoneNumber;
    this.text = text;
    this.created = created;
    this.updated = updated;
  }
}
