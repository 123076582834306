<template>
  <div>
    <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
      <div class="text-gray-800 bg-white">
        <div class="flex items-center justify-between py-4">
          <h2 class="text-3xl font-semibold">{{ $t("common.links") }}</h2>
          <router-link :to="{
            name: 'link-detail',
            params: { id: 'new', typeId: null, typeName: null },
          }">
            <button type="button"
              class="px-4 py-2 font-semibold text-white rounded bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.add") }}
            </button></router-link>
        </div>
      </div>

      <p v-if="pageLoaded && links?.length == 0" class="text-md sm:text-xl">
        {{ $t("links.need-to-create-some-links") }}
      </p>
      <p v-if="pageLoaded && links?.length == 0" class="text-md sm:text-xl">
        {{ $t("links.select-add-to-get-started") }}
      </p>

      <div v-else class="text-gray-800">
        <div class="flex py-2 ml-2 md:flex-row">
          <div class="relative mb-4 mr-4 md:mb-0">
            <!-- Add 'mb-4' class for spacing on mobile -->
            <label class="block mb-1 text-sm font-bold text-left">{{ $t("common.groups") }}:</label>
            <select v-model="selectedGroup"
              class="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-lynx-dark-blue">
              <option value="">{{ $t("common.all-groups") }}</option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="relative mr-4">
            <label class="block mb-1 text-sm font-bold text-left">{{ $t("common.types") }}:</label>
            <select v-model="selectedType"
              class="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-lynx-dark-blue">
              <option value="">{{ $t("common.all-types") }}</option>
              <option v-for="type in types" :key="type.id" :value="type.id">
                {{ $t("types." + type.code) }}
              </option>
            </select>
          </div>
        </div>

        <div class="table-header">
          <table class="w-full text-xs bg-white rounded shadow-md sm:mb-0 sm:text-sm">
            <thead>
              <tr class="border-b">
                <!-- Header columns go here -->
                <th v-for="column in columns" :key="column.name" @click.stop="sortByColumn(column.name)"
                  :class="column.width + ' p-3 px-2 text-left'">
                  {{ $t(column.name) }}
                  <span v-if="column.ascSort && column.selected" class="ml-1">&#8595;</span>
                  <span v-if="!column.ascSort && column.selected" class="ml-1">&#8593;</span>
                </th>
              </tr>
            </thead>
          </table>
        </div>

        <table-scroll :screenMaxHeight="300">
          <table class="w-full text-xs bg-white rounded shadow-md sm:text-sm">
            <tbody>
              <tr v-for="(link, index) in filteredLinks" :key="link.id" :class="{ 'bg-white': index % 2 }"
                class="bg-gray-100 border-b cursor-pointer hover:bg-orange-100" @click="navigateToDetail(link.id)">
                <td class="w-2/6 p-3 px-2 text-left">
                  {{ link.name }}
                </td>
                <td class="w-1/6 p-3 px-2 text-left">
                  {{ link.group }}
                </td>
                <td class="w-1/6 p-3 px-2 text-left">
                  {{ getTypeName(link.typeId) }}
                </td>
                <!-- <td class="w-1/6 p-3 px-2 text-left">
                {{ formatDate(link.updated) }}
              </td> -->

                <!-- Desktop buttons -->
                <td class="hidden w-2/6 p-3 px-2 text-right md:table-cell">
                  <button type="button"
                    class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue"
                    @click.stop="navigateToDetail(link.id)">
                    {{ $t("common.edit") }}
                  </button>
                  <button type="button"
                    class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue"
                    @click.stop="printLink(group.urlGuid)">
                    {{ $t("common.print") }}
                  </button>
                  <button @click.stop="deleteRow(link.id)" type="button"
                    class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 hover:bg-lynx-light-blue">
                    {{ $t("common.delete") }}
                  </button>
                </td>

                <!-- mobile buttons -->
                <td class="relative flex items-center justify-center px-5 py-3 md:hidden">
                  <button @click.stop="toggleDropdown(index)" type="button">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>

                  <!-- POP up dialog -->
                  <div v-if="activeRow === index"
                    class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 z-10 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                    <!-- Close button -->
                    <button class="absolute text-gray-500 cursor-pointer top-2 right-2" @click.stop="closeModal">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <ul>
                      <li class="m-4 font-semibold" @click.stop="navigateToDetail(link.id)">
                        {{ $t("common.edit") }}
                      </li>
                      <li class="m-4 font-semibold" @click.stop="printLink(link.urlGuid)">
                        {{ $t("common.print") }}
                      </li>
                      <li class="mx-4 border-t border-gray-300"></li>
                      <li class="m-4 font-semibold text-red-500" @click.stop="deleteRow(link.id)">
                        {{ $t("common.delete") }}
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </table-scroll>
      </div>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import TableScroll from "@/components/shared/TableScroll.vue";
import { getTypes } from "@/services/typeService";
import dialogService from "@/services/dialogService";
import { getGroups } from "@/services/groupService.js";
import {
  getLinks,
  deleteLinkById,
  linkIdIsUsedByHotSpot,
  linkIdIsUsedByScavengerHunt,
} from "@/services/linkService.js";

export default {
  components: {
    TableScroll,
    PageSpinner,
  },
  created() {
    this.getTypes();
    this.getGroups();
    this.getData();
  },
  mounted() {
    // Close the dropdown when clicking anywhere outside of it
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".w-48")) {
        this.activeRow = null;
      }
    });
  },
  data() {
    return {
      showSpinner: false,
      links: [],
      groups: [],
      types: [],
      selectedGroup: "",
      selectedType: "",
      activeRow: null,
      pageLoaded: false,
      columns: [
        {
          name: this.$t("common.name"),
          ascSort: false,
          width: "w-2/6",
          selected: false,
        },
        {
          name: this.$t("common.group"),
          ascSort: false,
          width: "w-1/6",
          selected: false,
        },
        {
          name: this.$t("common.type"),
          ascSort: false,
          width: "w-1/6",
          selected: false,
        },
        { name: "", ascSort: false, width: "w-2/6", selected: false },
      ],
    };
  },
  methods: {
    toggleDropdown(index) {
      this.activeRow = this.activeRow === index ? null : index;
    },
    closeModal() {
      this.activeRow = null;
    },
    async getData() {
      this.showSpinner = true;
      await getLinks()
        .then((data) => {
          this.links = data.sort(
            (a, b) => new Date(b.updated) - new Date(a.updated)
          );
          this.mapFieldNames("group", "groupId", this.groups);
          //this.mapFieldNames("type", "typeId", this.types);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.pageLoaded = true;
          this.showSpinner = false;
        });
    },
    mapFieldNames(fieldName, idFieldName, sourceArray) {
      const idToName = sourceArray.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      this.links = this.links.map((link) => ({
        ...link,
        [fieldName]: idToName[link[idFieldName]],
      }));
    },
    async deleteRow(id) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.delete-confirmation"),
      });

      if (!result) return;

      //Check all places that a link is not currently in use
      if (await linkIdIsUsedByHotSpot(id)) {
        this.$toast.warning(
          this.$t(
            "links.cannot-delete-the-link-as-it-is-associated-with-a-hot-spot"
          ),
          { position: "top-right" }
        );
        return;
      }

      if (await linkIdIsUsedByScavengerHunt(id)) {
        this.$toast.warning(
          this.$t(
            "links.cannot-delete-the-link-as-it-is-associated-with-a-scavenger-hunt"
          ),
          { position: "top-right" }
        );
        return;
      }

      //OK to delete
      this.showSpinner = true;
      await deleteLinkById(id)
        .then(() => {
          Object.fromEntries(
            Object.entries(this.links).filter(([key]) => key !== id)
          );

          this.links = this.links.filter((x) => x.id != id);
          this.$toast.success(this.$t("links.link-deleted"), {
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async getGroups() {
      this.showSpinner = true;
      await getGroups()
        .then((data) => {
          this.groups = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async getTypes() {
      this.showSpinner = true;
      await getTypes()
        .then((data) => {
          this.types = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    sortByColumn(name) {
      if (name === "") return;

      const selectedColumn = this.columns.find((x) => x.name === name);
      if (!selectedColumn) return;

      this.columns.forEach((column) => {
        column.ascSort = column.name !== name ? false : !selectedColumn.ascSort;
        column.selected = column.name !== name ? false : true;
      });

      const sortOrder = selectedColumn.ascSort ? -1 : 1;
      this.links.sort((a, b) => {
        const propA = a[name.toLowerCase()].toLowerCase();
        const propB = b[name.toLowerCase()].toLowerCase();

        return (propA < propB ? -1 : propA > propB ? 1 : 0) * sortOrder;
      });
    },
    formatDate(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      }
    },
    printLink(url) {
      this.$router.push(`/print-link/:${url}`);
    },
    navigateToDetail(linkId) {
      this.$router.push({
        name: "link-detail",
        params: {
          id: linkId,
          typeId: null,
          typeName: null,
        },
      });
    },
    getTypeName(linkId) {
      const matchingLink = this.types.find((x) => x.id === linkId);
      return matchingLink ? this.$t("types." + matchingLink.code) : "";
    },
  },
  computed: {
    filteredLinks() {
      if (this.selectedGroup === "" && this.selectedType === "") {
        return this.links; // No filtering needed, return all links
      } else {
        return this.links.filter((link) => {
          const matchesGroup =
            this.selectedGroup === "" || link.groupId === this.selectedGroup;
          const matchesType =
            this.selectedType === "" || link.typeId === this.selectedType;

          return matchesGroup && matchesType;
        });
      }
    },
  },
};
</script>

<style scoped></style>
