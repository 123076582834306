<template>
  <div>
    <div class="px-2">
      <div class="text-gray-800 bg-gray-200">
        <div class="flex py-2 ml-2 md:flex-row">
          <div class="relative mb-2 mr-2 md:mb-0">
            <label class="block mb-1 text-sm font-semibold">{{ $t("common.groups") }}:</label>
            <select v-model="selectedGroup"
              class="px-2 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:border-lynx-dark-blue">
              <option value="">{{ $t("common.all-groups") }}</option>
              <option v-for="group in groups" :key="group.id" :value="group.id">
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="relative mr-4">
            <label class="block mb-1 text-sm font-semibold">{{ $t("common.types") }}:</label>
            <select v-model="selectedType"
              class="px-2 py-2 text-sm border border-gray-300 rounded focus:outline-none focus:border-lynx-dark-blue">
              <option value="">{{ $t("common.all-types") }}</option>
              <option v-for="type in types" :key="type.id" :value="type.id">
                {{ $t("types." + type.code) }}
              </option>
            </select>
          </div>
        </div>

        <div class="overflow-x-auto max-h-32">
          <table class="w-full bg-white rounded shadow-md text-md">
            <tbody>
              <tr class="border-b">
                <th class="p-1 text-sm text-left">
                  {{
                    filteredLinks.length > 0
                    ? $t("links.select-link-for-your-hot-spot")
                    : $t("links.no-links-exist")
                  }}
                </th>
                <th></th>
              </tr>
              <tr v-for="(link, index) in filteredLinks" :key="link.id" :class="{ 'bg-white': index % 2 }"
                class="text-sm text-left bg-gray-100 border-b" @mouseenter="changeCursor" @mouseleave="restoreCursor"
                @click="handleRowClick(link)" style="cursor: default">
                <td>
                  {{ link.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import { getTypes } from "@/services/typeService";
import { getGroups } from "@/services/groupService.js";
import { getLinks } from "@/services/linkService.js";

export default {
  components: { PageSpinner },
  async created() {
    this.showSpinner = true;
    try {
      await this.getTypes();
      await this.getGroups();
      await this.getData();
    } finally {
      this.showSpinner = false;
    }
  },
  props: {
    linkId: String,
  },
  emits: ["selected-link"],
  data() {
    return {
      showSpinner: false,
      links: [],
      groups: [],
      types: [],
      selectedGroup: "",
      selectedType: "",
    };
  },
  methods: {
    changeCursor(event) {
      event.target.style.cursor = "pointer";
    },
    restoreCursor(event) {
      event.target.style.cursor = "default";
    },
    handleRowClick(link) {
      this.$emit("selected-link", link);
    },
    async getData() {
      await getLinks()
        .then((data) => {
          this.links = data.filter((x) => x.id != this.linkId); //We dont allow to link hotspot back to current link
          this.links = this.links.sort(
            (a, b) => new Date(b.updated) - new Date(a.updated)
          );
          this.mapFieldNames("group", "groupId", this.groups);
          this.mapFieldNames("type", "typeId", this.types);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    mapFieldNames(fieldName, idFieldName, sourceArray) {
      const idToName = sourceArray.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
      }, {});

      this.links = this.links.map((link) => ({
        ...link,
        [fieldName]: idToName[link[idFieldName]],
      }));
    },

    async getGroups() {
      await getGroups()
        .then((data) => {
          this.groups = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    async getTypes() {
      await getTypes()
        .then((data) => {
          this.types = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
  },
  computed: {
    filteredLinks() {
      if (this.selectedGroup === "" && this.selectedType === "") {
        return this.links; // No filtering needed, return all links
      } else {
        return this.links.filter((link) => {
          const matchesGroup =
            this.selectedGroup === "" || link.groupId === this.selectedGroup;
          const matchesType =
            this.selectedType === "" || link.typeId === this.selectedType;

          return matchesGroup && matchesType;
        });
      }
    },
    navigateToDetail() {
      return (id) => {
        return `/link-detail/${id}`;
      };
    },
  },
};
</script>

<style scoped></style>
