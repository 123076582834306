<template>
  <div class="">
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form @submit.prevent="handleSubmit" class="w-full px-4 py-4 bg-white rounded-md shadow-md mb-4">

          <div class="sm:flex">
            <!-- First Column -->
            <div class="sm:flex-1 sm:mr-4">
              <div class="  items-center mb-2    ">
                <label for="url" class="block font-semibold text-left text-gray-700">
                  {{ $t("type-links.enter-your-image-url") }}:
                </label>
                <div class="flex items-center mb-4">
                  <input type="text" id="url" :maxlength="2048" placeholder="https://" v-model="linkImage.url"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                    @input="validateImage" />
                </div>
                <p v-if="!isValidURL" class="text-red-500 text-left">
                  {{ $t("type-links.please-enter-a-valid-url") }}
                </p>
              </div>
            </div>
            <!-- second Column -->
            <div class="sm:flex-1 sm:mr-4">
              <div class="flex flex-col">
                <label class="block font-semibold text-left text-gray-700 mb-1" for="background" style="width: 150px;">
                  {{ $t("common.background") }}:</label>
                <input v-model="linkImage.backgroundColor" type="color" class="rounded cursor-pointer" :class="{
          backgroundColor: linkImage.backgroundColor,
          width: '20px',
          height: '20px',
        }" id="background" />
              </div>
            </div>
          </div>
        </form>

        <div :style="{ 'background-color': linkImage.backgroundColor }" :class="{ hidden: !isValidURL }">
          <div class="map_container">
            <img ref="image" :src="linkImage.url ||
          'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
          " />
          </div>
        </div>

        <form v-show="isQuillVisible" @submit.prevent="handleSubmit"
          class="w-full mt-4 px-4 py-4 bg-white rounded-md shadow-md mb-4">
          <quill-editor :style="{ 'background-color': linkImage.backgroundColor }" v-model:value="linkImage.text"
            :options="state.editorOption" :disabled="state.disabled" @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import { LinkImage } from "@/models/linkImage-model";
import {
  getLinkImageByLinkId,
  saveLinkImage,
} from "@/services/linkImageService.js";

export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        // placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            // ['link', 'image', 'video']
          ]
          // other moudle options here
          // otherMoudle: {}
        },
        // more options
      },
      disabled: false
    })

    const onEditorBlur = (quill) => {
      console.log('editor blur!', quill)
    }
    const onEditorFocus = (quill) => {
      console.log('editor focus!', quill)
    }
    const onEditorReady = (quill) => {
      //quill.focus();
      console.log('editor ready!', quill)
    }
    // const onEditorChange = ({ quill, html, text }) => {
    //   console.log('editor change!', text, quill, html)
    //   state._content = html
    // }

    return { state, onEditorBlur, onEditorFocus, onEditorReady }
  },
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-image-form-valid", false);
  },
  components: { quillEditor },
  props: {
    linkId: String,
  },
  emits: ["is-image-form-valid", "link-image"],
  data() {
    return {
      isQuillVisible: false,
      isValidURL: false,
      linkImage: new LinkImage(),
    };
  },
  methods: {
    async getLinkImageByLinkId() {
      if (this.linkId) {
        await getLinkImageByLinkId(this.linkId)
          .then((data) => {
            this.linkImage = data;
            this.validateImage();
            this.isQuillVisible = true;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkImage(linkId) {
      this.linkImage.linkId = linkId;

      await saveLinkImage(this.linkImage)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    validateImage() {
      try {
        new URL(this.linkImage.url);
        this.isValidURL = true;
        this.$emit("is-image-form-valid", true);
      } catch (error) {
        this.isValidURL = false;
        this.$emit("is-image-form-valid", false);
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkImageByLinkId();
      },
    },
    linkImage: {
      handler(updatedLinkImage) {
        this.$emit("link-image", updatedLinkImage);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.map_container {
  position: relative;
  display: inline-block;
}

.map_container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.map-marker {
  color: #fff;
  position: absolute;
  font-size: 20px;
  text-decoration: none;
  background-color: red;
  border-radius: 50%;
  padding: 10px 10px;
  width: 50px;
  height: 50px;
  top: 50%;
  /* Adjust this value to center vertically */
  left: 50%;
  /* Adjust this value to center horizontally */
  transform: translate(-50%,
      -50%);
  /* Center the circle both vertically and horizontally */
}
</style>
