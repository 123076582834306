<template>
  <div>
    <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
      <div class="flex items-center justify-between py-4">
        <h2 class="text-3xl font-semibold">{{ $t("common.link") }}</h2>

        <div class="flex justify-end mb-2 space-x-2 md:mt-0">
          <button @click="save()" type="button" :disabled="!formValid" :class="{
            'px-4 py-2 font-semibold text-white bg-lynx-dark-blue rounded': true,
            'sm:hover:bg-lynx-light-blue': formValid,
            'opacity-50 cursor-not-allowed': !formValid,
          }">
            {{ $t("common.save") }}
          </button>

          <button @click="test()" type="button" :disabled="!formValid" :class="{
            'px-4 py-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue': true,
            'sm:hover:bg-lynx-light-blue': formValid,
            'opacity-50 cursor-not-allowed': !formValid,
          }">
            {{ $t("common.test") }}
          </button>

          <button @click="$router.go(-1)" type="button"
            class="px-4 py-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue sm:hover:bg-lynx-light-blue hover:text-white hover:border-transparent">
            {{ $t("common.back") }}
          </button>
        </div>
      </div>

      <table-scroll :screenMaxHeight="210">
        <div class="mx-2 mt-2">
          <div class="flex items-center justify-between bg-white banner">
            <div class="">
              <!-- Tab A -->
              <button @click="setActiveTab('A')" :class="{ 'bg-lynx-light-blue  pt-4': activeTab === 'A' }"
                class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.detail") }}
              </button>

              <!-- Tab B -->
              <button :disabled="!link.id" @click="setActiveTab('B')" :class="{
                'bg-lynx-light-blue pt-4': activeTab === 'B',
                'opacity-50 cursor-not-allowed': !link.id,
              }" class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.barcode") }}
              </button>

              <!-- Tab C -->
              <button :disabled="!link.id" @click="setActiveTab('C')" :class="{
                'bg-lynx-light-blue pt-4': activeTab === 'C',
                'opacity-50 cursor-not-allowed': !link.id,
              }" class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.analytics") }}
              </button>
            </div>
          </div>

          <div v-show="activeTab === 'A'" class="flex flex-col p-4 bg-gray-100 md:flex-row">
            <div class="w-full mx-auto">
              <form @submit.prevent="handleSubmit" class="w-full px-4 py-8 bg-white rounded-md shadow-md">
                <div class="mb-2">
                  <label for="name" class="block mb-2 font-semibold text-left text-gray-700">
                    {{ $t("common.name") }} :
                    <span class="text-red-500">*</span></label>

                  <div class="flex items-center mb-4">
                    <input type="text" id="name" v-model="link.name" :maxlength="100"
                      class="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
                  </div>
                </div>

                <div class="mb-2">
                  <label for="text" class="block font-semibold text-left text-gray-700">
                    {{ $t("common.description") }} :
                  </label>

                  <textarea id="text" :maxlength="2048" v-model="link.text"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                    rows="4"></textarea>
                </div>

                <div class="sm:flex">
                  <!-- First Column -->
                  <div class="sm:flex-1 sm:mr-4">
                    <div class="mb-2">
                      <label for="type" class="block mb-2 font-semibold text-left text-gray-700">
                        {{ $t("common.type") }} :
                        <span class="text-red-500">*</span></label>
                      <select :disabled="link.id" id="type" v-model="link.typeId"
                        class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                        :class="{
                          'bg-gray-200 text-gray-500 cursor-not-allowed': link.id,
                        }">
                        <option v-for="option in typeOptions" :key="option.id" :value="option.id">
                          {{ $t("types." + option.code) }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <!-- Second Column -->
                  <div class="sm:flex-1">
                    <div class="mb-2">
                      <label for="group" class="block mb-2 font-semibold text-left text-gray-700">
                        {{ $t("common.group") }} :
                        <span class="text-red-500">*</span></label>
                      <select id="group" v-model="link.groupId"
                        class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue">
                        <option v-for="option in groupOptions" :key="option.id" :value="option.id">
                          {{ option.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <!-- Toggle Switch -->
                <div class="mt-8">
                  <label class="flex items-center cursor-pointer" @click="toggleSwitch">
                    <div class="mr-3 font-medium text-gray-700">
                      {{ $t("common.date-range") }}:
                    </div>
                    <div class="relative">
                      <div class="w-10 h-4 mt-1 bg-gray-400 rounded-full shadow-inner toggle__line"></div>
                      <div class="absolute inset-y-0 left-0 w-6 h-6 rounded-full shadow toggle__dot bg-blue" :class="{
                        'bg-blue-500 inset-y-0 left-5': link.dateRangeActive,
                        'bg-gray-300 inset-y-0 left-0': !link.dateRangeActive,
                      }"></div>
                    </div>
                  </label>

                  <div v-if="link.dateRangeActive" class="sm:flex">
                    <!-- Start Date Column -->
                    <div class="sm:flex-1 sm:mr-4">
                      <div class="mb-2">
                        <label for="startDate" class="block mb-2 font-semibold text-left text-gray-700">{{
                          $t("common.start-date") }}:
                        </label>
                        <input type="date" id="startDate" v-model="link.startDate"
                          class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
                      </div>
                    </div>

                    <!-- End Date Column -->
                    <div class="sm:flex-1">
                      <div class="mb-2">
                        <label for="endDate" class="block mb-2 font-semibold text-left text-gray-700">{{
                          $t("common.end-date") }}:
                        </label>
                        <input type="date" id="endDate" v-model="link.endDate"
                          class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <url-link v-if="this.link.type == 'link'" :initialUrl="link.url" @url-change="link.url = $event"
                @is-url-form-valid="linkComponentValid = $event"></url-link>
              <text-link ref="textLinkRef" v-if="this.link.type == 'text'" :linkId="link.id"
                @link-text="linkText = $event" @is-text-form-valid="linkComponentValid = $event"></text-link>
              <call-link ref="callLinkRef" v-if="this.link.type == 'call'" :linkId="link.id"
                @link-call="linkCall = $event" @is-call-form-valid="linkComponentValid = $event"></call-link>
              <sms-link ref="smsLinkRef" v-if="this.link.type == 'sms'" :linkId="link.id" @link-sms="linkSms = $event"
                @is-sms-form-valid="linkComponentValid = $event"></sms-link>
              <mail-link ref="mailLinkRef" v-if="this.link.type == 'email'" :linkId="link.id"
                @link-email="linkEmail = $event" @is-email-form-valid="linkComponentValid = $event"></mail-link>
              <image-link ref="imageLinkRef" v-if="this.link.type == 'image'" :linkId="link.id"
                @link-image="linkImage = $event" @is-image-form-valid="linkComponentValid = $event"></image-link>
              <hot-spot-link ref="hotSpotLinkRef" v-if="this.link.type == 'hotSpot'" :linkId="link.id"
                @edit-link-from-hotspot="reNavigateToDetail($event)" @link-hot-spot="linkHotSpot = $event"
                @is-hotspot-form-valid="linkComponentValid = $event"></hot-spot-link>
            </div>
          </div>
        </div>

        <style-code ref="styleCodeRef" v-show="activeTab === 'B'" :url="generateLink" :linkId="link.id"
          :urlGuid="link.urlGuid" :externalUrl="link.externalUrl"></style-code>

        <div class="mx-2 mb-2">
          <div v-show="activeTab === 'C'" class="flex flex-col p-4 bg-gray-100 md:flex-row">
            <div class="w-full mx-auto">
              <div class="w-full p-4 bg-white rounded-md shadow-md">
                <div v-if="link.id" class="text-left">
                  <div>
                    <span class="font-bold">{{ $t("common.created") }}: </span><span class="text-xs">{{
                      formatDate(link.created) }}</span>
                  </div>
                  <div>
                    <span class="font-bold">{{ $t("common.updated") }}: </span><span class="text-xs">{{
                      formatDate(link.updated) }}</span>
                  </div>
                  <div>
                    <span class="font-bold">{{ $t("common.scanned") }}: </span><span class="text-xs">{{ link.scanned
                    }}</span>
                  </div>
                </div>
                <div v-if="!link.id" class="mt-4 text-left">
                  {{ $t("common.no-data") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </table-scroll>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import TableScroll from "@/components/shared/TableScroll.vue";
import StyleCode from "@/components/qr/barcode/StyleCode.vue";
import { Link } from "@/models/link-model";
import dialogService from "@/services/dialogService";
import { getGroups } from "@/services/groupService.js";
import { getTypes } from "@/services/typeService";
import { getLinkById, saveLink } from "@/services/linkService.js";
import URLLink from "@/components/qr/types/URLLink";
import TextLink from "@/components/qr/types/TextLink";
import CallLink from "@/components/qr/types/CallLink";
import SmsLink from "@/components/qr/types/SmsLink";
import MailLink from "@/components/qr/types/MailLink";
import HotSpotLink from "@/components/qr/types/HotSpotLink.vue";
import ImageLink from "@/components/qr/types/ImageLink.vue";

export default {
  created() {
    this.link.id = this.$route.params.id || "new";

    this.getTypes().then(() => {
      if (this.$route.params.typeId) {
        this.link.typeId = this.$route.params.typeId;
        this.link.type = this.$route.params.typeName;
      }
    });
    this.getGroups();
    this.getDetailById(this.$route.params.id);
  },
  components: {
    PageSpinner,
    TableScroll,
    "style-code": StyleCode,
    "url-link": URLLink,
    "text-link": TextLink,
    "call-link": CallLink,
    "sms-link": SmsLink,
    "mail-link": MailLink,
    "hot-spot-link": HotSpotLink,
    "image-link": ImageLink,
  },
  data() {
    return {
      showSpinner: false,
      formValid: false,
      qrSize: this.calculateQrSize(),
      link: new Link(),
      linkEmail: {},
      linkText: {},
      linkCall: {},
      linkSms: {},
      linkHotSpot: {},
      linkImage: {},

      typeOptions: [],
      groupOptions: [],

      linkComponentValid: false,
      activeTab: "A", // Default active tab
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    toggleSwitch() {
      this.link.dateRangeActive = !this.link.dateRangeActive;
    },
    async test() {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.details-will-be-saved-first"),
        showCancel: false,
      });


      if (result) {
        await this.save();
      } else {
        return;
      }

      if (this.link.urlGuid) {
        const url = this.$router.resolve({
          name: "redirect",
          params: { urlGuid: this.link.urlGuid },
        }).href;

        window.open(url, "_blank");
      }
    },
    setLinkType(newTypeId) {
      this.link.type = this.typeOptions.find((x) => x.id == newTypeId).code;
    },
    async reNavigateToDetail(id) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.save-changes-first"),
        showCancel: true,
      });

      if (result == null) return;
      if (result) {
        await this.save();
      }

      this.link.type = null;
      this.link.id = id;
      this.getDetailById();
    },
    async getDetailById() {
      if (this.link.id === "new") {
        this.link.id = null;
        return;
      }
      this.showSpinner = true;
      await getLinkById(this.link.id)
        .then((data) => {
          this.link = data;
          this.setLinkType(this.link.typeId);

          //Get barcode style

        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async save() {
      this.showSpinner = true;
      await saveLink(this.link)
        .then(async (data) => {

          //Save bar code details
          var styleCodeRefComponent = this.$refs.styleCodeRef;
          await styleCodeRefComponent.saveLinkBarCodeStyle(data.id);


          switch (this.link.type) {
            case "hotSpot":
              var hotspotLinkRefComponent = this.$refs.hotSpotLinkRef;
              await hotspotLinkRefComponent.saveLinkHotSpot(data.id);
              break;
            case "image":
              var imageLinkRefComponent = this.$refs.imageLinkRef;
              await imageLinkRefComponent.saveLinkImage(data.id);
              break;
            case "email":
              var mailLinkRefComponent = this.$refs.mailLinkRef;
              await mailLinkRefComponent.saveLinkEmail(data.id);
              break;
            case "text":
              var textLinkRefComponent = this.$refs.textLinkRef;
              await textLinkRefComponent.saveLinkText(data.id);
              break;
            case "call":
              var callLinkRefComponent = this.$refs.callLinkRef;
              await callLinkRefComponent.saveLinkCall(data.id);
              break;
            case "sms":
              var smsLinkRefComponent = this.$refs.smsLinkRef;
              await smsLinkRefComponent.saveLinkSms(data.id);
              break;
          }
          this.$toast.success(this.$t("links.link-saved"), {
            position: "top-right",
          });

          this.link.id = data.id;
          this.getDetailById();
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async getGroups() {
      this.showSpinner = true;
      await getGroups()
        .then((data) => {
          this.groupOptions = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(async () => {
          this.showSpinner = false;
          if (this.groupOptions.length == 0) {
            await dialogService.open({
              okOnly: true,
              title: this.$t("groups.no-groups-exist"),
              message: this.$t("groups.please-add-at-least-one-group"),
            });

            this.$router.push("/groups");
          }
        });
    },
    async getTypes() {
      this.showSpinner = true;
      await getTypes()
        .then((data) => {
          this.typeOptions = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    validateForm(formData) {
      //Validate the components we interested in
      const requiredFields = ["name", "groupId", "typeId"];

      this.formValid = requiredFields.every((field) => !!formData[field]);
      if (!this.linkComponentValid) this.formValid = false;
    },
    formatDate(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
      }
    },
    calculateQrSize() {
      if (window.innerWidth <= 768) {
        // Mobile & Tablets
        return 200;
      } else {
        // Desktops
        return 300;
      }
    },
  },
  computed: {
    generateLink() {
      let link = "";
      switch (this.link.type) {
        case "hotSpot":
          link = this.linkHotSpot.url;
          break;
        case "image":
          link = this.linkImage.url;
          break;
        case "link":
          link = this.link.url;
          break;
        case "text":
          link = this.linkText.text;
          break;
        case "call":
          link = `tel:${this.linkCall.phoneNumber}`;
          break;
        case "sms":
          link = `sms:${this.linkSms.phoneNumber}?body=${this.linkSms.text}`;
          break;
        case "email":
          link = `mailto:${this.linkEmail.email}?subject=${encodeURIComponent(
            this.linkEmail.subject
          )}&body=${encodeURIComponent(this.linkEmail.text)}`;
          break;
      }

      return link;
    },
  },
  watch: {
    linkComponentValid() {
      this.validateForm(this.link);
    },

    "link.typeId"(newTypeId) {
      this.setLinkType(newTypeId);
    },

    link: {
      handler(newValue) {
        this.validateForm(newValue);
      },
      deep: true,
    },
  },
};
</script>
