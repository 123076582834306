import "firebase/compat/firestore";
import { firestore } from "../components/firebase";

const db = firestore;

export const getLinkCallByLinkId = async (linkId) => {
  const querySnapshot = await db
    .collection("link_call")
    .where("linkId", "==", linkId)
    .get();

  if (!querySnapshot.empty) {
    // Since there could be multiple matching documents, you might want to handle them accordingly.
    // For now, just return the first matching document.
    const docSnapshot = querySnapshot.docs[0];
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link Call not found");
  }
};

export const saveLinkCall = async (data) => {

  const linkCallCollection = db.collection("link_call");

  if (data.id) {
    // Update existing link_Call
    await linkCallCollection.doc(data.id).update({
      linkId: data.linkId,
      phoneNumber: data.phoneNumber.toString(),
      updated: new Date().toISOString(),
    });
  } else {
    // Create new link_call
    await linkCallCollection.add({
      linkId: data.linkId,
      phoneNumber: data.phoneNumber.toString(),
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteLinkCallById = async (id) => {
  const docRef = db.collection("link_call").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Link Call not found");
  }
};
