export class Group {
  constructor(
    id = "",
    name = "",
    description = "",
    customerUid = "",
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.customerUid = customerUid;
    this.created = created;
    this.updated = updated;
  }
}
