<template>
  <!-- Use the PopupComponent as a popup -->
  <div>
    <pop-up v-if="isPopupVisible" @close="closePopup">
      <!-- Popup content goes here -->
      <link-select :linkId="linkId" @selected-link="handleLinkSelected"></link-select>
    </pop-up>
  </div>
  <div class="">
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form @submit.prevent="handleSubmit" class="w-full px-4 py-4  mb-4 bg-white rounded-md shadow-md">


          <div class="sm:flex">
            <!-- First Column -->
            <div class="sm:flex-1 sm:mr-4">
              <div class="  items-center mb-2    ">
                <label for="url" class="block font-semibold text-left text-gray-700">
                  {{ $t("type-links.enter-your-image-url") }}:
                </label>
                <div class="flex items-center mb-4">
                  <input type="text" id="url" :maxlength="2048" placeholder="https://" v-model="linkHotSpot.url"
                    class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                    @input="validateHotSpot" />
                </div>
                <p v-if="!isValidURL" class="text-red-500 text-left">
                  {{ $t("type-links.please-enter-a-valid-url") }}
                </p>
              </div>
            </div>
            <!-- second Column -->
            <div class="sm:flex-1 sm:mr-4">
              <div class="flex flex-col">
                <label class="block font-semibold text-left text-gray-700 mb-1" for="background" style="width: 150px;">
                  {{ $t("common.background") }}:</label>
                <input v-model="linkHotSpot.backgroundColor" type="color" class="rounded cursor-pointer" :class="{
      backgroundColor: linkHotSpot.backgroundColor,
      width: '20px',
      height: '20px',
    }" id="background" />
              </div>
            </div>
          </div>

        </form>

        <div :class="{ hidden: !isValidURL }">
          <label for="number" class="block font-semibold text-left text-sm mt-4">
            {{ $t("type-links.tap-on-the-image-to-add-a-hot-spot") }}:
          </label>

          <div class="map_container">
            <img ref="image" :src="linkHotSpot.url ||
      'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
      " @click="placeMarker" />
            <!-- Loop through linkHotSpot.hotspots -->
            <a v-for="(marker, index) in linkHotSpot.hotspots" :key="index" :class="[
      'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white w-6 h-6 sm:w-12 sm:h-12 flex items-center justify-center rounded-full',
      'marker-' + (index + 1),
    ]" :style="{
      left: marker.left,
      top: marker.top,
    }" @click.stop="handleHotSpotClick(index)">
              <div class="text-xs sm:text-2xl" aria-hidden="true">
                {{ index + 1 }}
              </div>
            </a>
          </div>

          <table v-if="linkHotSpot.hotspots.length > 0" class="w-full bg-white rounded-md shadow-md">
            <thead>
              <tr>
                <th class="text-left">{{ $t("common.hotspots") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(hotspot, index) in linkHotSpot.hotspots" :key="hotspot.linkId"
                :class="{ 'bg-white': index % 2 }" class="bg-gray-100 border-b cursor-pointer hover:bg-orange-100"
                @click="navigateToDetail(hotspot.linkId)">
                <td class="text-left">{{ index + 1 }} - {{ hotspot.name }}</td>

                <!-- desktop buttons -->
                <td class="hidden w-2/6 p-3 px-2 text-right md:table-cell">
                  <button type="button"
                    class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue"
                    @click.stop="navigateToDetail(hotspot.linkId)">
                    {{ $t("common.edit") }}
                  </button>

                  <button @click.stop="handleHotSpotClick(index)" type="button"
                    class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue">
                    {{ $t("common.remove") }}
                  </button>
                </td>

                <!-- mobile buttons -->
                <td class="relative flex items-center justify-center px-5 py-3 sm:hidden">
                  <button @click.stop="toggleDropdown(index)" type="button">
                    <i class="fa fa-ellipsis-v"></i>
                  </button>

                  <!-- POP up dialog -->
                  <div v-if="activeRow === index"
                    class="fixed z-10 w-48 p-2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-300 rounded-md shadow-lg top-1/2 left-1/2">
                    <!-- Close button -->
                    <button class="absolute text-gray-500 cursor-pointer top-2 right-2" @click.stop="closeModal">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                        class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <ul>
                      <li class="m-4 font-semibold" @click.stop="navigateToDetail(hotspot.linkId)">
                        {{ $t("common.edit") }}
                      </li>
                      <li class="mx-4 border-t border-gray-300"></li>
                      <li class="m-4 font-semibold text-red-500" @click.stop="handleHotSpotClick(index)">
                        {{ $t("common.remove") }}
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <form v-show="isQuillVisible" @submit.prevent="handleSubmit"
      class="  w-full mt-4 px-4 py-4 bg-white rounded-md shadow-md mb-4">
      <quill-editor :style="{ 'background-color': linkHotSpot.backgroundColor }" v-model:value="linkHotSpot.text"
        :options="state.editorOption" :disabled="state.disabled" @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)" @ready="onEditorReady($event)" />
    </form>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import LinkSelect from "@/components/qr/links/LinkSelect.vue";
import PopUp from "@/components/shared/PopUp.vue";
import { LinkHotSpot } from "@/models/linkHotSpot-model";
import {
  getLinkHotSpotByLinkId,
  saveLinkHotSpot,
} from "@/services/linkHotSpotService.js";
import dialogService from "@/services/dialogService";

export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        // placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            // ['link', 'image', 'video']
          ]
          // other moudle options here
          // otherMoudle: {}
        },
        // more options
      },
      disabled: false
    })

    const onEditorBlur = (quill) => {
      console.log('editor blur!', quill)
    }
    const onEditorFocus = (quill) => {
      console.log('editor focus!', quill)
    }
    const onEditorReady = (quill) => {
      //quill.focus();
      console.log('editor ready!', quill)

    }
    // const onEditorChange = ({ quill, html, text }) => {
    //   console.log('editor change!', text, quill, html)
    //   state._content = html
    // }

    return { state, onEditorBlur, onEditorFocus, onEditorReady }
  },
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-hotspot-form-valid", false);

    // Close the dropdown when clicking anywhere outside of it
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".w-48")) {
        this.activeRow = null;
      }
    });
  },
  components: {
    quillEditor,
    "link-select": LinkSelect,
    "pop-up": PopUp,
  },
  props: {
    linkId: String,
  },
  emits: ["is-hotspot-form-valid", "link-hot-spot", "edit-link-from-hotspot"],
  data() {
    return {
      isQuillVisible: false,
      isPopupVisible: false,
      isValidURL: false,
      linkHotSpot: new LinkHotSpot(),
      activeRow: null,
    };
  },
  methods: {
    toggleDropdown(index) {
      this.activeRow = this.activeRow === index ? null : index;
    },
    closeModal() {
      this.activeRow = null;
    },
    async handleHotSpotClick(index) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.remove-confirmation"),
      });

      if (!result) return;

      // Remove the hotspot when clicked
      this.linkHotSpot.hotspots.splice(index, 1);
    },
    navigateToDetail(linkId) {
      this.$emit("edit-link-from-hotspot", linkId);
    },
    removeHotSpot(event) {
      // Check if the click is within the bounds of any hotspot
      for (let i = 0; i < this.linkHotSpot.hotspots.length; i++) {
        const marker = this.linkHotSpot.hotspots[i];
        const hotspotElement = this.$refs[`hotspot-${i}`]; // Add a ref to each hotspot element

        // Calculate the bounds of the hotspot
        const hotspotLeft = marker.left;
        const hotspotTop = marker.top;
        const hotspotRight = hotspotLeft + hotspotElement.offsetWidth;
        const hotspotBottom = hotspotTop + hotspotElement.offsetHeight;

        // Check if the click is within the bounds of the hotspot
        if (
          event.clientX >= hotspotLeft &&
          event.clientX <= hotspotRight &&
          event.clientY >= hotspotTop &&
          event.clientY <= hotspotBottom
        ) {
          // Click is inside the hotspot, remove it
          this.linkHotSpot.hotspots.splice(i, 1);
          break; // Exit the loop after removing the hotspot
        }
      }
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup(value) {
      this.isPopupVisible = false;
      //If nothing chosen then remove last hotspot from array
      if (!value) {
        this.linkHotSpot.hotspots.pop();
      }
    },
    async getLinkHotSpotByLinkId() {
      if (this.linkId) {
        await getLinkHotSpotByLinkId(this.linkId)
          .then((data) => {
            this.linkHotSpot = data;
            this.validateHotSpot();
            this.isQuillVisible = true;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkHotSpot(linkId) {
      this.linkHotSpot.linkId = linkId;

      await saveLinkHotSpot(this.linkHotSpot).catch((error) => {
        this.$toast.error(error, { position: "top-right", duration: 0 });
      });
    },
    validateHotSpot() {
      try {
        new URL(this.linkHotSpot.url);
        this.isValidURL = true;
        this.$emit("is-hotspot-form-valid", true);
      } catch (error) {
        this.isValidURL = false;
        this.$emit("is-hotspot-form-valid", false);
      }
    },
    placeMarker(event) {
      this.showPopup();
      const image = this.$refs.image;
      const imageRect = image.getBoundingClientRect();

      // Calculate marker position relative to the image's dimensions
      const markerLeft =
        ((event.clientX - imageRect.left) / imageRect.width) * 100;
      const markerTop =
        ((event.clientY - imageRect.top - 10) / imageRect.height) * 100;

      // Push the new marker's position into the linkHotSpot.hotspots array
      this.linkHotSpot.hotspots.push({
        left: markerLeft + "%",
        top: markerTop + "%",
      });
    },
    handleLinkSelected(row) {
      // Handle the selected row data here
      this.selectedRow = row;
      this.isPopupVisible = false;
      this.linkHotSpot.hotspots[this.linkHotSpot.hotspots.length - 1].linkId =
        row.id;
      this.linkHotSpot.hotspots[this.linkHotSpot.hotspots.length - 1].name =
        row.name;
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkHotSpotByLinkId();
      },
    },
    linkHotSpot: {
      handler(updatedLinkHotSpot) {
        this.$emit("link-hot-spot", updatedLinkHotSpot);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.map_container {
  position: relative;
  display: inline-block;
}

.map_container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.map-marker {
  color: #fff;
  position: absolute;
  font-size: 20px;
  text-decoration: none;
  background-color: red;
  border-radius: 50%;
  padding: 10px 10px;
  width: 50px;
  height: 50px;
  top: 50%;
  /* Adjust this value to center vertically */
  left: 50%;
  /* Adjust this value to center horizontally */
  transform: translate(-50%,
      -50%);
  /* Center the circle both vertically and horizontally */
}
</style>
