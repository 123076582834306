<template>
  <div class="flex h-full my-4 ">
    <div class="flex items-center justify-center w-full max-w-xl mx-auto my-6">
      <div
        class="p-8 mt-20 mx-auto bg-white border rounded shadow login-container"
      >
        <h2 class="mb-8 text-2xl font-semibold">{{$t("login.register")}}</h2>

        <div class="mt-4 email-login">
          <input
            type="email"
            v-model="email"
            :placeholder="$t('common.email')"
            class="w-full px-4 py-2 border rounded"
          />
          <input
            type="password"
            v-model="password"
            :placeholder="$t('common.password')"
            class="w-full px-4 py-2 mt-2 border rounded"
          />
        </div>
        <button
          @click="register"
          class="w-full px-4 py-2 mt-4 text-white transition duration-300 bg-green-400 rounded cursor-pointer hover:bg-green-500"
        >
        {{$t("login.register")}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/components/firebase.js";
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    register() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((result) => {
          // User registered successfully
          const user = result.user;
          console.log(user);
          this.$router.push('/landing');
        })
        .catch((error) => {
          // Handle registration error
          this.$toast.info(error, { position: "top-right"  });
        });
    },
  },
};
</script>
