import "firebase/compat/firestore";
import { firestore, auth } from "../components/firebase";

const db = firestore;

export const getScavengerHuntByGameId = async (gameId) => {
  const querySnapshot = await db
    .collection("game_scavenger_hunt")
    .where("gameId", "==", gameId)
    .get();

  if (!querySnapshot.empty) {
    // Since there could be multiple matching documents, you might want to handle them accordingly.
    // For now, just return the first matching document.
    const docSnapshot = querySnapshot.docs[0];

    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Scavenger Hunt not found");
  }
};

export const saveScavengerHunt = async (data) => {
  const linkTextCollection = db.collection("game_scavenger_hunt");

  if (data.id) {
    // Update existing game_scavenger_hunt
    await linkTextCollection.doc(data.id).update({
      gameId: data.gameId,
      linkIds: data.linkIds,
      themeId: data.themeId,
      customerUid: auth.currentUser.uid,
      updated: new Date().toISOString(),
    });
  } else {
    // Create new game_scavenger_hunt
    await linkTextCollection.add({
      gameId: data.gameId,
      linkIds: data.linkIds,
      themeId: data.themeId,
      customerUid: auth.currentUser.uid,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteScavengerHuntByGameId = async (gameId) => {
  const querySnapshot = await db.collection("game_scavenger_hunt")
    .where("gameId", "==", gameId)
    .get();

  if (!querySnapshot.empty) {
    // Assuming there is only one document with the given gameId, you can use the first one
    const docRef = querySnapshot.docs[0].ref;

    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Scavenger Hunt not found for gameId: " + gameId);
  }

};
