<template>
  <header>
    <nav class="flex items-center justify-between p-0 bg-gray-800 navbar">
      <!-- Left side: Logo and Title -->
      <router-link to="/landing" class="flex items-center">
        <!-- Image -->
        <img src="@/assets/img/logo.png" alt="Logo" class="w-16 h-32 mr-2" />

        <!-- Title -->

        <div>
          <span
            style="font-size: 1.65rem"
            class="font-semibold text-lynx-light-green sm:text-2xl"
            >LYNXED-UP</span
          >
        </div>
      </router-link>

      <!-- Right side: Login Button -->
      <div class="hidden sm:block mr-2">
        <div v-if="isLoggedIn">
          <i class="mr-2 fa fa-user fa-lg text-lynx-light-green"></i>
          <span class="text-lynx-light-green mr-2">{{ userName }}</span>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
.navbar {
  height: 64px; /* Adjust the height as needed */
}

.navbar img {
  height: 100%;
}
</style>

<script>
import { auth } from "@/components/firebase";
export default {
  props: {
    isLoggedIn: Boolean,
  },
  computed: {
    userName() {
      return auth.currentUser.displayName;
    },
  },
};
</script>
