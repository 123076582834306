<template>
  <button @click="initiatePhoneCall">Initiate Phone Call</button>
  <div>Calling...</div>
</template>

<script>
import { LinkCall } from "@/models/linkCall-model";
import { getLinkCallByLinkId } from "@/services/linkCallService.js";

export default {
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkCallByLinkId();
    });
  },
  data() {
    return {
      urlGuid: "",
      linkCall: new LinkCall(),
    };
  },
  methods: {
    async getLinkCallByLinkId() {
      if (this.urlGuid) {
        await getLinkCallByLinkId(this.urlGuid)
          .then((data) => {
            this.linkCall = data;

            this.initiatePhoneCall();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    initiatePhoneCall() {
      const phoneNumber = this.linkCall.phoneNumber;
      window.location.href = `tel:${phoneNumber}`;
    },
  },
};
</script>
