<template>
  <footer>
    <nav>
      <div class="bg-gray-800">
        <div
          class="flex flex-nowrap gap-4 font-mono text-white text-sm font-bold leading-6 rounded-md"
        >
          <div class="px-2 w-full rounded-md flex items-center justify-center">
            <router-link
              to="/dashboard"
              class="flex flex-row items-center h-12 text-lynx-light-blue transition-transform duration-200 ease-in transform hover:translate-x-2 hover:text-gray-100"
            >
              <div
                class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              >
                <i class="fa fa-qrcode"></i>
              </div>
            </router-link>
          </div>

          <div class="px-2 w-full rounded-md flex items-center justify-center">
            <router-link
              to="/links"
              class="flex flex-row items-center h-12 text-lynx-light-blue transition-transform duration-200 ease-in transform hover:translate-x-2 hover:text-gray-100"
            >
              <div
                class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              >
                <i class="fa fa-link"></i>
              </div>
            </router-link>
          </div>

          <div class="px-2 w-full rounded-md flex items-center justify-center">
            <router-link
              to="/groups"
              class="flex flex-row items-center h-12 text-lynx-light-blue transition-transform duration-200 ease-in transform hover:translate-x-2 hover:text-gray-100"
            >
              <div
                class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              >
                <i class="fa fa-object-group"></i>
              </div>
            </router-link>
          </div>

          <div class="px-2 w-full rounded-md flex items-center justify-center">
            <router-link
              to="/gamesMain"
              class="flex flex-row items-center h-12 text-lynx-light-blue transition-transform duration-200 ease-in transform hover:translate-x-2 hover:text-gray-100"
            >
              <div
                class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              >
                <i class="fas fa-gamepad"></i>
              </div>
            </router-link>
          </div>

          <div class="px-2 w-full rounded-md flex items-center justify-center">
            <a
              href="#"
              @click="logout"
              class="flex flex-row items-center h-12 text-lynx-light-blue transition-transform duration-200 ease-in transform hover:translate-x-2 hover:text-gray-100"
            >
              <div
                class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              >
                <i class="bx bx-log-out"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </footer>
</template>

<script>
import dialogService from "@/services/dialogService";
import firebase from "@/components/firebase.js";

export default {
  data() {
    return {};
  },
  methods: {
    async logout() {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.logout-confirmation"),
      });

      if (!result) return;

      firebase
        .auth()
        .signOut()
        .then(() => {
          // Log out successful
          this.$router.push("/landing");
        })
        .catch((error) => {
          // Handle logout error
          console.error(error);
        });
    },
  },
};
</script>
