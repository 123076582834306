import "firebase/compat/firestore";
import { firestore } from "../components/firebase";

const db = firestore;

export const getTypes = async () => {
  const querySnapshot = await db.collection("types").get();

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getTypeById = async (id) => {
  const docRef = db.collection("types").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Type not found");
  }
};

