<template>
  <div
    ref="sidebar"
    :class="{
      'w-0 sm:w-16': !isSidebarOpen,
      'w-full sm:w-64': isSidebarOpen,
      'transition-width': true,
    }"
    class="fixed z-50 mt-16 bg-gray-800 sm:h-full left-30"
  >
    <!-- Toggle button/icon -->
    <button
      @click.stop="toggleSidebar"
      class="fixed top-0 right-0 p-2 mt-1 text-white md:static md:float-right focus:outline-none"
    >
      <i
        v-if="isSidebarOpen"
        class="mt-2 mr-1 fa fa-times sm:mt-0"
        aria-hidden="true"
        style="font-size: 24px"
      ></i>
      <i
        v-else
        class="mt-2 mr-1 fa fa-bars sm:mt-0"
        aria-hidden="true"
        style="font-size: 24px"
      ></i>
    </button>

    <!-- Sidebar content here -->
    <div :class="{ 'hidden sm:block p-4': !isSidebarOpen }">
      <ul class="flex flex-col py-4">
        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/landing"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-home"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.home")
            }}</span>
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/dashboard"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-qrcode"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium"
              >{{ $t("sidebar.dashboard") }}
            </span>
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/links"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-link"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.links")
            }}</span>
          </router-link>
        </li>
        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/groups"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-object-group"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.groups")
            }}</span>
          </router-link>
        </li>

        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/gamesMain"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fas fa-gamepad"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.gaming")
            }}</span>
          </router-link>
        </li>

        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/analytics"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-chart-line"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium"
              >{{ $t("sidebar.analytics") }}
            </span>
          </router-link>
        </li>

        <li class="border-b border-white sm:border-none">
          <router-link
            to="/pricing"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-coins"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.pricing")
            }}</span>
          </router-link>
        </li>

        <li class="border-b border-white sm:border-none">
          <router-link
            to="/faq"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="fa fa-info"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.faq")
            }}</span>
          </router-link>
        </li>

        <li class="border-b border-white sm:border-none">
          <div
            @click="showLanguageDialog()"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
            >
              <i class="fas fa-globe-americas"></i>
            </span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">
              {{ $t("sidebar.language") }}
            </span>
          </div>
        </li>

        <li v-if="isLoggedIn" class="border-b border-white sm:border-none">
          <a
            href="#"
            @click="logout"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="bx bx-log-out"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.sign-out")
            }}</span>
          </a>
        </li>

        <li v-if="!isLoggedIn" class="border-b border-white sm:border-none">
          <router-link
            to="/login"
            class="flex flex-row items-center h-12 transition-transform duration-200 ease-in transform text-lynx-light-blue hover:translate-x-2 hover:text-gray-100"
          >
            <span
              class="inline-flex items-center justify-center w-12 h-12 text-lg text-lynx-light-blue"
              ><i class="bx bx-log-in"></i
            ></span>
            <span v-if="isSidebarOpen" class="text-sm font-medium">{{
              $t("sidebar.sign-in")
            }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>

  <!-- Use the PopupComponent as a popup -->
  <div>
    <pop-up v-if="showLanguagePopup" @close="showLanguagePopup = false">
      <!-- Popup content goes here -->
      <dialog-language-select
        @close-dialog="showLanguagePopup = false"
      ></dialog-language-select>
    </pop-up>
  </div>
</template>

<script>
import dialogService from "@/services/dialogService";
import firebase from "@/components/firebase.js";
import DialogLanguageSelect from "@/components/shared/DialogLanguageSelect.vue";
import PopUp from "@/components/shared/PopUp.vue";

export default {
  components: {
    "pop-up": PopUp,
    "dialog-language-select": DialogLanguageSelect,
  },
  emits: ["toggle-sidebar"],
  mounted() {
    // Close the dropdown when clicking anywhere outside of it
    document.addEventListener("click", (e) => {
      // Get a reference to the sidebar element using this.$refs
      const sidebar = this.$refs.sidebar;

      // Check if the clicked element is outside of the sidebar
      if (!sidebar.contains(e.target)) {
        this.isSidebarOpen = false;
      }
    });
  },
  created() {
    // Add a route change listener to close the sidebar
    this.$router.beforeEach((to, from, next) => {
      if (this.isSmScreen() && this.isSidebarOpen) {
        this.isSidebarOpen = false;
      }
      next();
    });
  },
  props: {
    isLoggedIn: Boolean,
  },
  data() {
    return {
      isSidebarOpen: false,
      showLanguagePopup: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      this.$emit("toggle-sidebar");
    },
    async logout() {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.logout-confirmation"),
      });

      if (!result) return;

      firebase
        .auth()
        .signOut()
        .then(() => {
          // Log out successful
          this.$router.push("/landing");
        })
        .catch((error) => {
          // Handle logout error
          console.error(error);
        });
    },
    isSmScreen() {
      // Function to check if the screen size is "sm" or smaller
      return window.innerWidth < 640;
    },
    showLanguageDialog() {
      this.showLanguagePopup = true;
    },
  },
};
</script>

<style scoped></style>
