<template>
  <div>
    <!-- Backdrop -->
    <div v-if="isVisible" class="fixed inset-0 z-40 bg-black opacity-50"></div>

    <!-- Dialog -->
    <div
      v-if="isVisible"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div class="w-4/5 sm:w-1/2 p-8 bg-white rounded-md shadow-lg">
        <h2 class="mb-4 text-lg font-semibold">
          {{ title }}
          <hr />
        </h2>
        <p class="mb-4">{{ message }}</p>

        <div v-if="!okOnly" class="flex justify-end">
          <button
            class="px-4 py-2 mr-2 font-semibold text-white rounded-md bg-lynx-dark-blue hover:bg-lynx-light-blue focus:outline-none"
            @click="handleYes"
          >
            {{ $t("common.yes") }}
          </button>
          <button
            class="px-4 py-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
            @click="handleNo"
          >
            {{ $t("common.no") }}
          </button>

          <button
            v-if="showCancel"
            class="px-4 py-2 ml-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
            @click="handleCancel"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>

        <div v-if="okOnly" class="flex justify-end">
          <button
            class="px-4 py-2 mr-2 font-semibold text-white rounded-md bg-lynx-dark-blue hover:bg-lynx-light-blue focus:outline-none"
            @click="handleOK"
          >
            {{ $t("common.ok") }}
          </button>

          <button
            v-if="showCancel"
            class="px-4 py-2 ml-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
            @click="handleCancel"
          >
            {{ $t("common.cancel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    okOnly: Boolean,
    showCancel: Boolean,
    title: String,
    message: String,
    onClose: Function, // This is the function to resolve the promise
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    handleYes() {
      this.onClose(true); // Resolve the promise with true
      this.isVisible = false;
    },
    handleNo() {
      this.onClose(false); // Resolve the promise with false
      this.isVisible = false;
    },
    handleOK() {
      this.onClose(true); // Resolve the promise with true
      this.isVisible = false;
    },
    handleCancel() {
      this.onClose(null); // Resolve the promise with true
      this.isVisible = false;
    },
  },
};
</script>
