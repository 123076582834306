import "firebase/compat/firestore";
import { firestore, auth } from "../components/firebase";

const db = firestore;

export const getGames = async () => {
  const querySnapshot = await db
    .collection("games")
    .where("customerUid", "==", auth.currentUser.uid)
    .get();

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getGameById = async (id) => {
  const docRef = db.collection("games").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Game not found");
  }
};

export const saveGame = async (data) => {
  const gamesCollection = db.collection("games");

  if (data.id) {
    // Create/Update game
    await gamesCollection.doc(data.id).update({
      name: data.name,
      description: data.description,
      customerUid: auth.currentUser.uid,
      groupId: data.groupId,
      typeId: data.typeId,
      published: data.published,
      price: data.price,
      updated: new Date().toISOString(),
    });
    return data;
  } else {
    // Create new game
    const response = await gamesCollection.add({
      name: data.name,
      description: data.description,
      customerUid: auth.currentUser.uid,
      groupId: data.groupId,
      typeId: data.typeId,
      published: data.published,
      price: data.price,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });

    // Get the newly created document's ID
    const newGameId = response.id;

    // Get the newly created document's data
    const newGameSnapshot = await response.get();
    const newGameData = newGameSnapshot.data();

    // Add the ID to the data model
    newGameData.id = newGameId;

    // Return the data model with ID included
    return newGameData;
  }
};

export const deleteGameById = async (id) => {
  const docRef = db.collection("games").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Game not found");
  }
};

export const getGameTypes = async () => {
  const querySnapshot = await db.collection("game_types").get();

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getGameTypeById = async (id) => {
  const docRef = db.collection("game_types").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Type not found");
  }
};
