<template>
  <div>Sending email...</div>
</template>

<script>
import { LinkEmail } from "@/models/linkEmail-model";
import { getLinkEmailByLinkId } from "@/services/linkEmailService.js";

export default {
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkEmailByLinkId();  
    });
  },
  data() {
    return {
      urlGuid: "",
      linkEmail: new LinkEmail(),
    };
  },
  methods: {
    async getLinkEmailByLinkId() {
      if (this.urlGuid) {
        await getLinkEmailByLinkId(this.urlGuid)
          .then((data) => {
            this.linkEmail = data;

            this.sendEmail(this.linkEmail);
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    sendEmail(linkEmail) {
      const emailAddress = linkEmail.email;
      const subject = linkEmail.subject;
      const body = linkEmail.text;

      // Encode components separately
      const encodedSubject = encodeURIComponent(subject);
      const encodedBody = encodeURIComponent(body);

      // Use a template string to create the mailto link
      const mailtoLink = `mailto:${emailAddress}?subject=${encodedSubject}&body=${encodedBody}`;

      // Create an anchor element and simulate a click to open the mailto link
      const mailtoAnchor = document.createElement("a");
      mailtoAnchor.href = mailtoLink;

      // Open the link in a new tab/window
      window.open(mailtoAnchor.href, "_blank");
    },
  },
};
</script>
