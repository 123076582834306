import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import "../index.css";
import router from "./routing.js";

import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

import App from "./App.vue";
import SideBar from "./components/shared/SideBar.vue";
import NavBar from "./components/shared/NavBar.vue";
import NavBarFooter from "./components/shared/NavBarFooter.vue";

const app = createApp(App);

app.component("side-bar", SideBar);
app.component("nav-bar", NavBar);
app.component("nav-bar-footer", NavBarFooter);

app.use(router);
app.use(ToastPlugin);

// import translations
import de from "../src/assets/i18n/de.json";
import en from "../src/assets/i18n/en.json";
import fr from "../src/assets/i18n/fr.json";

// configure i18n
const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: { de, en,fr },
});

app.use(i18n);

app.mount("#app");

export { i18n };