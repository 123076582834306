export class LinkEmail {
  constructor(
    id = "",
    linkId = "",
    email = "",
    subject = "",
    text = "",
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.linkId = linkId;
    this.email = email;
    this.subject = subject;
    this.text = text;
    this.created = created;
    this.updated = updated;
  }
}
