<template>
  <div>
    <nav-bar
      v-if="!isRouteNameAllowed($route.name)"
      :isLoggedIn="isLoggedIn"
      class="fixed top-0 left-0 right-0 z-50"
    ></nav-bar>
    <div class="h-screen">
      <!-- Sidebar -->

      <Sidebar
        v-if="!isRouteNameAllowed($route.name)"
        :isLoggedIn="isLoggedIn"
        @toggle-sidebar="toggleSidebar"
      />

      <nav-bar-footer
        v-if="isLoggedIn && !isRouteNameAllowed($route.name)"
        class="fixed bottom-0 left-0 right-0 z-50 sm:hidden"
      />

      <!-- Main content area -->
      <div
        :class="{
          'sm:ml-64': isSidebarOpen && !this.isRouteNameAllowed($route.name),
          'sm:ml-16': !isSidebarOpen && !this.isRouteNameAllowed($route.name),
          'ml-0': this.isRouteNameAllowed($route.name),
        }"
        class="flex-1 transition-all font-roboto"
      >
        <div class="w-full h-full rounded">
          <router-view 
            class="router-view-container h-full mx-0 sm:mx-5 md:mx-10 lg:mx-15 1xl:mx-10 2xl:mx-10"
          ></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/shared/SideBar.vue";
import firebase from "./components/firebase";

export default {
  components: {
    Sidebar,
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in
        this.isLoggedIn = true;
      } else {
        // User is signed out
        this.isLoggedIn = false;
      }
    });
  },
  data() {
    return {
      allowedRouteNames: [
        "redirect",
        "redirectHotSpot",
        "redirectText",
        "redirectCall",
        "redirectEmail",
        "redirectImage" ,
        "redirectSms"
      ],
      isSidebarOpen: false,
      isLoggedIn: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    isRouteNameAllowed(routeName) {
      return this.allowedRouteNames.includes(routeName);
    },
  },
};
</script>

<style>
.font-roboto {
  font-family: 'Roboto', sans-serif;
}
</style>

<style>
.router-view-container {
  max-width: 1200px; /* Adjust the value based on your design and user experience considerations */
  margin: 0 auto; /* Center the content horizontally */
  width: 100%; /* Ensure the container takes up the full width when the screen is smaller than the max-width */
}
</style>
<!-- <style>
/* Hide the scrollbar in webkit-based browsers */
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

/* Optionally, you can style the thumb and track if needed */
::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* Hide the scrollbar in Firefox */
div::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* For Firefox, you can use scrollbar-width property */
div {
  scrollbar-width: none;
}
</style> -->
