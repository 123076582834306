<template>
  <div class="flex flex-col py-4 bg-gray-100 md:flex-row" :class="themeColorClass">
    <div class="w-full mx-auto">

      <div class="w-full px-4 py-4 bg-white rounded-md shadow-md">

        <div class="sm:flex">
          <!-- First Column -->
          <div class="sm:flex-1 sm:mr-4">
            <div class="  items-center mb-2    ">
              <label for="theme" class="block font-semibold text-left text-gray-700">
                {{ $t("common.theme") }} :
              </label>
              <select id="theme" v-model="linkText.themeId"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue">
                <option v-for="option in themes" :key="option.id" :value="option.id">
                  {{ option.theme }}
                </option>
              </select>
            </div>
          </div>
          <!-- second Column -->
          <div class="sm:flex-1 sm:mr-4">
            <div @click="linkText.themeId = 0" class="flex flex-col">
              <label class="block font-semibold text-left text-gray-700 mb-1" for="background" style="width: 150px;">
                {{ $t("common.background") }}:</label>
              <input v-model="linkText.backgroundColor" type="color" class="rounded cursor-pointer" :class="{
                backgroundColor: linkText.backgroundColor,
                width: '20px',
                height: '20px',
              }" id="background"  />
            </div>


          </div>
        </div>





        <quill-editor :style="{ 'background-color': linkText.backgroundColor }" v-model:value="linkText.text"
          :options="state.editorOption" :disabled="state.disabled" @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)" @ready="onEditorReady($event)" @change="onEditorChange($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import { LinkText } from "@/models/linkText-model";
import {
  getLinkTextByLinkId,
  saveLinkText,
} from "@/services/linkTextService.js";
export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        // placeholder: '',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ['clean'],
            // ['link', 'image', 'video']
          ]
          // other moudle options here
          // otherMoudle: {}
        },
        // more options
      },
      disabled: false
    })

    const onEditorBlur = (quill) => {
      console.log('editor blur!', quill)
    }
    const onEditorFocus = (quill) => {
      console.log('editor focus!', quill)
    }
    const onEditorReady = (quill) => {
      //quill.focus();
      console.log('editor ready!', quill)
    }
    // const onEditorChange = ({ quill, html, text }) => {
    //   console.log('editor change!', text, quill, html)
    //   state._content = html
    // }

    return { state, onEditorBlur, onEditorFocus, onEditorReady }
  },
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-text-form-valid", false);
  },
  props: {
    linkId: String,
  },
  components: {
    quillEditor
  },
  emits: ["is-text-form-valid", "link-text"],
  data() {
    return {
      linkText: new LinkText(),
      themes: [
        { id: 0, theme: 'None' },
        { id: 1, theme: 'Map' },
        { id: 2, theme: 'Blue' },
        { id: 3, theme: 'Hearts' },
        { id: 4, theme: 'Leaves' },
        { id: 5, theme: 'Paper1' },
        { id: 6, theme: 'Paper2' },
      ],
    };
  },
  methods: {
    onEditorChange() {
      // Handle change event
      console.log('Editor content changed:', this.linkText.text);
      this.validateText();
    },
    async getLinkTextByLinkId() {
      if (this.linkId) {
        await getLinkTextByLinkId(this.linkId)
          .then((data) => {
            this.linkText = data;
            this.validateText();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkText(linkId) {
      this.linkText.linkId = linkId;
      await saveLinkText(this.linkText).catch((error) => {
        this.$toast.error(error, { position: "top-right", duration: 0 });
      });
    },
    validateText() {
      if (this.linkText.text?.length > 0) {
        this.$emit("is-text-form-valid", true);
      } else {
        this.$emit("is-text-form-valid", false);
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkTextByLinkId();
      },
    },
    linkText: {
      handler(updatedLinkText) {
        this.$emit("link-text", updatedLinkText);
      },
      deep: true,
    },
  },
  computed: {
    themeColorClass() {
      switch (this.linkText.themeId) {
        case 1:
          return 'bg-theme1';
        case 2:
          return 'bg-theme2';
        case 3:
          return 'bg-theme3';
        case 4:
          return 'bg-theme4';
        case 5:
          return 'bg-theme5';
        case 6:
          return 'bg-theme6';
        default:
          return 'bg-theme0';
      }
    },
  }
};
</script>
<style>
.bg-theme0 {
  background-image: none;
}

.bg-theme1 {
  .ql-editor {
    background-image: url("@/assets/img/textures/mapTexture.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme2 {
  .ql-editor {
    background-image: url("@/assets/img/textures/blueTexture.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme3 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Hearts.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme4 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Leaves.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme5 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Paper1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme6 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Paper2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
 