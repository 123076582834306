<template>
  <div :style="{ 'background-color': linkHotSpot.backgroundColor }" class="map-container-wrapper">
    <div class="map_container">
      <img ref="image" :src="linkHotSpot.url" @error="handleImageError" />
      <quill-editor :style="{ 'background-color': linkHotSpot.backgroundColor }" class="h-fill "
        v-model:value="linkHotSpot.text" :options="state.editorOption" :disabled="state.disabled" />

      <!-- Loop through linkHotSpot.hotspots -->
      <a v-for="(marker, index) in linkHotSpot.hotspots" :key="index" :class="[
        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-red-500 text-white w-6 h-6 sm:w-12 sm:h-12 flex items-center justify-center rounded-full',
        'marker-' + (index + 1),
      ]" :style="{
  left: marker.left,
  top: marker.top,
}" @click.stop="handleHotSpotClick(index)">
        <div class="text-xs sm:text-2xl" aria-hidden="true">
          {{ index + 1 }}
        </div>
      </a>
    </div>


  </div>


  <button @click="closeWindow()" class="z-10 absolute text-gray-500 top-2 right-2 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import { LinkHotSpot } from "@/models/linkHotSpot-model";
import { getLinkById } from "@/services/linkService.js";
import { getLinkHotSpotByLinkId } from "@/services/linkHotSpotService.js";

export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: false

        },
      },
      disabled: true
    })
    return { state }
  },
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkHotSpotByLinkId();
    });
  },
  components: {
    quillEditor
  },
  data() {
    return {
      urlGuid: "",
      linkHotSpot: new LinkHotSpot(),
    };
  },
  methods: {
    closeWindow() {
      // Close the current window/tab
      window.close();
    },
    async getLinkHotSpotByLinkId() {
      if (this.urlGuid) {
        await getLinkHotSpotByLinkId(this.urlGuid)
          .then((data) => {
            this.linkHotSpot = data;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    handleImageError() {
      // default blank image
      this.linkHotSpot.url =
        "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    },
    async handleHotSpotClick(index) {
      await getLinkById(this.linkHotSpot.hotspots[index].linkId)
        .then((data) => {
          this.$router.push("/redirect/" + data.urlGuid);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
  },
};
</script>

<style scoped>
.map-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Optional: Center vertically on the whole viewport height */
}

.map_container {
  position: relative;
  display: inline-block;
}

.map_container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.map-marker {
  color: #fff;
  position: absolute;
  font-size: 20px;
  text-decoration: none;
  background-color: red;
  border-radius: 50%;
  padding: 10px 10px;
  width: 50px;
  height: 50px;
  top: 50%;
  /* Adjust this value to center vertically */
  left: 50%;
  /* Adjust this value to center horizontally */
  transform: translate(-50%,
      -50%);
  /* Center the circle both vertically and horizontally */
}

.ql-container.ql-snow {
  border: none !important;
}
</style>
