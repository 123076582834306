<template>
  <div class="flex h-full px-4 py-6  pt-16 md:py-12 lg:py-16">
    <div class="flex items-center justify-center w-full max-w-xl mx-auto my-6">
      <div class="p-8 mx-auto bg-white border rounded shadow login-container">
        <h2 class="mb-8 text-2xl font-semibold">{{ $t("login.sign-in") }}</h2>
        <div class="login-methods">
          <label class="block mb-4">
            <input type="radio" v-model="selectedMethod" value="google" />
            {{ $t("login.sign-in-with-google") }}
          </label>
          <label class="block">
            <input type="radio" v-model="selectedMethod" value="email" />
            {{ $t("login.sign-in-with-email-and-password") }}
          </label>
        </div>
        <div v-if="selectedMethod === 'email'" class="mt-4 email-login">
          <input
            type="email"
            v-model="email"
            :placeholder="$t('common.email')"
            class="w-full px-4 py-2 border rounded"
          />
          <input
            type="password"
            v-model="password"
            :placeholder="$t('common.password')"
            class="w-full px-4 py-2 mt-2 border rounded"
            @keypress.enter="login"
          />
        </div>
        <button
          @click="login"
          class="w-full px-4 py-2 mt-4 text-white transition duration-300 rounded cursor-pointer bg-lynx-dark-blue hover:bg-lynx-light-blue"
        >
          {{ $t("login.sign-in") }}
        </button>
        <br />
        <router-link to="/registerUser">
          <button
            class="w-full px-4 mt-4 text-white transition duration-300 border rounded cursor-pointer bg-lynx-light-green hover:bg-lynx-light-blue"
          >
            {{ $t("login.register") }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "@/components/firebase.js";
export default {
  data() {
    return {
      selectedMethod: "google",
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      if (this.selectedMethod === "google") {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: "select_account",
        });

        firebase
          .auth()
          .signInWithPopup(provider)
          .then(() => {
            // User signed in successfully
            this.$router.push("/dashboard");
          })
          .catch(() => {
            // Handle sign-in error
            this.$toast.error(
              "The email address or password is incorrect. Please retry...",
              { position: "top-right" }
            );
          });
      } else if (this.selectedMethod === "email") {
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            // User logged in successfully
            this.$router.push("/dashboard");
          })
          .catch(() => {
            // Handle login error
            this.$toast.error(
              "The email address or password is incorrect. Please retry...",
              { position: "top-right" }
            );
          });
      }
    },
  },
};
</script>

<style scoped></style>
