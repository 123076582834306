export class LinkText {
  constructor(
    id = "",
    linkId = "",
    text = "",
    themeId = 0,
    backgroundColor = "",
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.linkId = linkId;
    this.text = text;
    this.themeId = themeId;
    this.backgroundColor = backgroundColor;
    this.created = created;
    this.updated = updated;
  }
}
