<template>
  <div
    class="overflow-x-hidden overflow-y-scroll sm:overflow-y-auto table-body"
    :style="{ height: screenHeight - screenMaxHeight + 'px'  }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  mounted() {
    this.screenHeight = window.innerHeight;
    // update the height if the window is resized
    window.addEventListener("resize", this.updateScreenHeight);
  },
  props: {
    screenMaxHeight: Number,
  },
  data() {
    return {
      screenHeight: 0,
    };
  },
  methods: {
    updateScreenHeight() {
      this.screenHeight = window.innerHeight;
    },
  },
  beforeUnmount() {
    // Remove the resize event listener to avoid memory leaks
    window.removeEventListener("resize", this.updateScreenHeight);
  },
};
</script>

<!-- <style>
.table-body {
  overflow-x: hidden;
  overflow-y: hidden; /* Initially hide the vertical scrollbar */
  transition: overflow 0.3s; /* Add a smooth transition effect */
}

.table-body:hover {
  overflow-y: auto; /* Show the vertical scrollbar on hover */
}</style> -->
