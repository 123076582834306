export class ScavengerHunt {
  constructor(
    id = "",
    gameId = "",
    linkIds = "",
    themeId = 0,
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.gameId = gameId;
    this.linkIds = linkIds;
    this.themeId = themeId;
    this.created = created;
    this.updated = updated;
  }
}
