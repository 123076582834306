import "firebase/compat/firestore";
import { firestore } from "../components/firebase";

const db = firestore;

export const getLinkImageByLinkId = async (linkId) => {
  const querySnapshot = await db.collection("link_image").where("linkId", "==", linkId).get();

  if (!querySnapshot.empty) {
    // Since there could be multiple matching documents, you might want to handle them accordingly.
    // For now, just return the first matching document.
    const docSnapshot = querySnapshot.docs[0];
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link Image not found");
  }
};

export const saveLinkImage = async (data) => {
  const linkImageCollection = db.collection("link_image");

  if (data.id) {
    // Update existing link_Image
    await linkImageCollection.doc(data.id).update({
      linkId: data.linkId,
      url: data.url,
      backgroundColor: data.backgroundColor,
      text: data.text,
      updated: new Date().toISOString(),
    });
  } else {
    // Create new link_hot_spot
    await linkImageCollection.add({
      linkId: data.linkId,
      url: data.url,
      backgroundColor: data.backgroundColor,
      text: data.text,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteLinkImageById = async (id) => {
  const docRef = db.collection("link_image").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Link Image not found");
  }
};
