<template>
  <!-- Use the PopupComponent as a popup -->
  <div>
    <pop-up v-if="isPopupVisible" @close="closePopup">
      <!-- Popup content goes here -->
      <link-select :linkId="linkId" @selected-link="handleLinkSelected"></link-select>
    </pop-up>
  </div>

  <div class="w-full">
    <div class="text-gray-800 bg-white rounded-md">
      <div class="flex items-center justify-between py-4">
        <h2 class="mx-2 font-semibold">{{ $t("common.locations") }}:</h2>

        <button @click="showPopup()" type="button"
          class="px-4 py-2 mx-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue sm:hover:bg-lynx-light-blue hover:text-white hover:border-transparent">
          {{ $t("common.add") }}
        </button>
      </div>




      <!-- <div class="mb-2 mx-2 sm:w-1/2">
        <label for="theme" class="block mb-2 font-semibold text-left text-gray-700">
          {{ $t("common.theme") }} :
        </label>
        <select id="theme" v-model="scavengerHunt.themeId"
          class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue">
          <option v-for="option in themes" :key="option.id" :value="option.id">
            {{ option.theme }}
          </option>
        </select>
      </div> -->


      <div class="ml-2 text-sm text-left">
        <pr v-if="links.length > 0">
          {{ $t("gaming.scavenger-hunt.drag-to-reorder") }}:
        </pr>
        <pr v-else>
          {{ $t("gaming.scavenger-hunt.select-add-to-get-started") }}
        </pr>
      </div>

      <div v-if="links.length > 0">
        <table-scroll :screenMaxHeight="250">
          <div>
            <draggable :list="links" v-model="links" handle=".handle" class="flex flex-wrap">
              <template #item="{ element, index }">
                <div @click.stop="navigateToDetail(links[index].id)" :key="index"
                  class="relative flex flex-col w-full p-4 m-2 border border-gray-500 rounded-md cursor-pointer bg-lynx-light-green  sm:w-40 sm:hover:bg-lynx-light-blue"
                  :class="themeColorClass">
                  <i class="absolute top-0 left-0 p-2 fas fa-grip-vertical handle"></i>
                  <span class="flex-grow">{{ index + 1 }}</span>
                  <span class="flex-grow">{{ element.name }}</span>
                  <div class="absolute top-0 right-0 p-2">
                    <i @click.stop="deleteRow(links[index].id)" class="fa fa-trash"></i>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </table-scroll>
      </div>
    </div>
  </div>


  <!-- <button class="bg-red-400" @click="getAI()">AI MAGIC</button> -->
</template>

<script>
import { generateScavengerHunt } from "@/services/chatGptService";
import draggable from "vuedraggable";
import LinkSelect from "@/components/qr/links/LinkSelect.vue";
import PopUp from "@/components/shared/PopUp.vue";
import dialogService from "@/services/dialogService";
import { ScavengerHunt } from "@/models/scavengerHunt-model";
import {
  saveScavengerHunt,
  getScavengerHuntByGameId,
} from "@/services/gameScavengerHuntService";
import { getLinkById } from "@/services/linkService";
export default {
  inject: ["saveParent"],
  mounted() { },
  props: {
    gameId: String,
  },
  components: {
    LinkSelect,
    PopUp,
    draggable,
  },
  data() {
    return {
      links: [],
      themes: [{ id: 0, theme: 'none' }, { id: 1, theme: 'map' }, { id: 2, theme: 'other' }],
      linkId: String,
      scavengerHunt: new ScavengerHunt(),
      isPopupVisible: false,
      draggedIndex: null,
      activeRow: null,
    };
  },
  methods: {
    async getAI() {
      //Work in progress. Testing AI
      await generateScavengerHunt(5)
        .then((data) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleDropdown(index) {
      this.activeRow = this.activeRow === index ? null : index;
    },
    closeModal() {
      this.activeRow = null;
    },
    showPopup() {
      this.isPopupVisible = true;
    },
    closePopup() {
      this.isPopupVisible = false;
      //If nothing chosen then remove last hotspot from array
    },
    async getScavengerHuntByGameId() {
      if (this.gameId) {
        this.showSpinner = true;
        await getScavengerHuntByGameId(this.gameId)
          .then((data) => {
            this.scavengerHunt = data;
            if (data.linkIds) {
              let tempLinkIdArray = data.linkIds.split(",");
              tempLinkIdArray.forEach((linkId) => {
                this.getLinkById(linkId);
              });
            } else {
              this.scavengerHunt.themeId = 0
            }
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          })
          .finally(() => {
            this.showSpinner = false;
          });
      }
    },
    async getLinkById(linkId) {
      this.showSpinner = true;
      await getLinkById(linkId)
        .then((data) => {
          this.links.push(data);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async saveScavengerHunt(gameId) {
      this.scavengerHunt.gameId = gameId;

      // Read all the links used and store their id's
      this.scavengerHunt.linkIds = this.links.map((item) => item.id).join(",");

      this.showSpinner = true;
      await saveScavengerHunt(this.scavengerHunt)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async deleteRow(id) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.remove-confirmation"),
      });

      if (!result) return;

      this.links = this.links.filter((x) => x.id != id);

      this.showSpinner = true;
      this.saveScavengerHunt(this.scavengerHunt.gameId)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async navigateToDetail(linkId) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.save-changes-first"),
        showCancel: true,
      });

      this.isPopupVisible = false;
      if (result == null) return;
      if (result) {
        await this.saveParent();
      }

      this.$router.push({
        name: "link-detail",
        params: {
          id: linkId,
          typeId: null,
          typeName: null,
        },
      });
    },
    handleLinkSelected(selectedLink) {
      this.isPopupVisible = false;
      this.links.push(selectedLink);
    },
    handleDragStart(index) {
      this.draggedIndex = index;
    },
    handleDragOver(index) {
      if (this.draggedIndex !== null) {
        const draggedLink = this.links[this.draggedIndex];
        this.links.splice(this.draggedIndex, 1);
        this.links.splice(index, 0, draggedLink);
        this.draggedIndex = index;
      }
    },
    handleDrop(index) {
      console.log(index);
      // Prevent default behavior of drop
      event.preventDefault();
    },
    handleDragEnd() {
      this.draggedIndex = null;
    },
  },
  watch: {
    gameId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getScavengerHuntByGameId();
      },
    },
  },
  computed: {
    themeColorClass() {
      switch (this.scavengerHunt.themeId) {
        case 1:
          return 'bg-theme1';
        case 2:
          return 'bg-theme2';
        default:
          return 'bg-theme0';
      }
    },
  }
}
</script>

<style scoped>
tr[draggable] {
  cursor: move;
}

tr[draggable]:hover {
  background-color: #f7fafc;
}

.bg-theme0 {
  background-image: none;
}

.bg-theme1 {
  background-image: url('@/assets/img/textures/mapTexture.jpg');
}

.bg-theme2 {
  background-image: url('@/assets/img/textures/blueTexture.jpg');
}
</style>
