<template>
  <div>
    <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
      <div class="text-gray-800 bg-white">
        <div class="flex items-center justify-between py-4">
          <h2 class="text-3xl font-semibold">{{ $t("common.games") }}</h2>
          <router-link to="/game-detail/new">
            <button type="button"
              class="px-4 py-2 font-semibold text-white rounded bg-lynx-dark-blue hover:bg-lynx-light-blue">
              {{ $t("common.add") }}
            </button></router-link>
        </div>
      </div>

      <div class="table-header">
        <table class="w-full text-xs bg-white rounded shadow-md sm:mb-0 sm:text-sm">
          <thead>
            <tr class="border-b">
              <!-- Header columns go here -->
              <th v-for="column in columns" :key="column.name" @click.stop="sortByColumn(column.name)"
                :class="column.width + ' p-3 px-2 text-left'">
                {{ $t(column.name) }}
                <span v-if="column.ascSort && column.selected" class="ml-1">&#8595;</span>
                <span v-if="!column.ascSort && column.selected" class="ml-1">&#8593;</span>
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <table-scroll :screenMaxHeight="250">
        <table class="w-full text-xs bg-white rounded shadow-md sm:text-sm">
          <tbody>
            <tr v-for="(game, index) in games" :key="game.id" :class="{ 'bg-white': index % 2 }"
              class="bg-gray-100 border-b cursor-pointer hover:bg-orange-100" @click="navigateToDetail(game.id)">
              <td class="w-2/5 p-3 px-2 text-left align-top">
                {{ game.name }}
              </td>
              <td class="w-2/5 p-3 px-2 text-left">
                {{
                  game.showMoreDescription
                  ? game.description
                  : game.description.slice(0, defaultDescriptionLength) +
                  (game.description.length > defaultDescriptionLength
                    ? "..."
                    : "")
                }}
                <span v-if="game.description?.length > defaultDescriptionLength">
                  <button @click.stop="
                    game.showMoreDescription = !game.showMoreDescription
                    " class="ml-2 text-lynx-dark-blue hover:underline focus:outline-none">
                    {{
                      game.showMoreDescription
                      ? this.$t("common.less")
                      : this.$t("common.more")
                    }}
                  </button>
                </span>
              </td>

              <!-- desktop buttons -->
              <td class="hidden w-2/6 p-3 px-2 text-right md:table-cell">
                <button type="button"
                  class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue"
                  @click.stop="navigateToDetail(game.id)">
                  {{ $t("common.edit") }}
                </button>

                <button @click.stop="deleteRow(game.id)" type="button"
                  class="mb-1 text-xs border border-lynx-light-blue rounded-full px-2 py-0.5 mr-1 hover:bg-lynx-light-blue">
                  {{ $t("common.delete") }}
                </button>
              </td>

              <!-- mobile buttons -->
              <td class="relative flex items-center justify-center px-5 py-3 sm:hidden">
                <button @click.stop="toggleDropdown(index)" type="button">
                  <i class="fa fa-ellipsis-v"></i>
                </button>

                <!-- POP up dialog -->
                <div v-if="activeRow === index"
                  class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 z-10 w-48 bg-white border border-gray-300 rounded-md shadow-lg">
                  <!-- Close button -->
                  <button class="absolute text-gray-500 cursor-pointer top-2 right-2" @click.stop="closeModal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                      class="w-4 h-4">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <ul>
                    <li class="m-4 font-semibold" @click.stop="navigateToDetail(game.id)">
                      {{ $t("common.edit") }}
                    </li>
                    <li class="mx-4 border-t border-gray-300"></li>
                    <li class="m-4 font-semibold text-red-500" @click.stop="deleteRow(game.id)">
                      {{ $t("common.delete") }}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </table-scroll>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import TableScroll from "@/components/shared/TableScroll.vue";
import dialogService from "@/services/dialogService";
import { getGames, deleteGameById } from "@/services/gameService.js";
import { deleteScavengerHuntByGameId } from "@/services/gameScavengerHuntService.js";

export default {
  components: {
    TableScroll,
    PageSpinner,
  },
  created() {
    this.getData();
  },
  mounted() {
    // Close the dropdown when clicking anywhere outside of it
    document.addEventListener("click", (e) => {
      if (!e.target.closest(".w-48")) {
        this.activeRow = null;
      }
    });
  },
  data() {
    return {
      showSpinner: false,
      columns: [
        {
          name: this.$t("common.name"),
          ascSort: false,
          width: "w-2/5",
          selected: false,
        },
        {
          name: this.$t("common.description"),
          ascSort: false,
          width: "w-2/5",
          selected: false,
        },
        { name: "", ascSort: false, width: "w-1/5", selected: false },
      ],
      games: [],
      activeRow: null,
      defaultDescriptionLength: 100,
    };
  },
  methods: {
    toggleDropdown(index) {
      this.activeRow = this.activeRow === index ? null : index;
    },
    closeModal() {
      this.activeRow = null;
    },
    sortByColumn(name) {
      if (name === "") return;

      const selectedColumn = this.columns.find((x) => x.name === name);
      if (!selectedColumn) return;

      this.columns.forEach((column) => {
        column.ascSort = column.name !== name ? false : !selectedColumn.ascSort;
        column.selected = column.name !== name ? false : true;
      });

      const sortOrder = selectedColumn.ascSort ? -1 : 1;

      this.games.sort((a, b) => {
        const propA = a[name.toLowerCase()].toLowerCase();
        const propB = b[name.toLowerCase()].toLowerCase();
        return (propA < propB ? -1 : propA > propB ? 1 : 0) * sortOrder;
      });
    },
    async getData() {
      this.showSpinner = true;
      await getGames()
        .then((data) => {
          this.games = data.map((game) => ({
            ...game,
            showMoreDescription: false,
          }));
          this.games.sort((a, b) => new Date(b.updated) - new Date(a.updated));
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },

    async deleteRow(id) {
      const result = await dialogService.open({
        okOnly: false,
        title: this.$t("common.confirmation"),
        message: this.$t("common.delete-confirmation"),
      });

      if (!result) return;

      this.showSpinner = true;
      await deleteScavengerHuntByGameId(id)
        .then(async () => {
          await deleteGameById(id)
            .then(() => {
              Object.fromEntries(
                Object.entries(this.games).filter(([key]) => key !== id)
              );
              this.games = this.games.filter((x) => x.id != id);

              this.$toast.success(this.$t("gaming.game-deleted"), {
                position: "top-right",
              });
            })
            .catch((error) => {
              this.$toast.error(error, { position: "top-right", duration: 0 });
            })
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    navigateToDetail(gameId) {
      this.$router.push(`/game-detail/${gameId}`);
    },
  },
};
</script>

<style scoped></style>
