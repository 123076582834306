<template>
 
    <div :style="{ 'background-color': linkImage.backgroundColor }" class="map-container-wrapper">
      <div class="map_container">
        <img ref="image" :src="linkImage.url" @error="handleImageError" />
        <quill-editor :style="{ 'background-color': linkImage.backgroundColor }" class="h-fill "
        v-model:value="linkImage.text" :options="state.editorOption" :disabled="state.disabled" />
  
      </div>
    </div>
   
      
  






  <button @click="closeWindow()" class="z-10 absolute text-gray-500 top-2 right-2 hover:text-gray-700">
    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </button>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import { LinkImage } from "@/models/linkImage-model";
import { getLinkImageByLinkId } from "@/services/linkImageService.js";

export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: false

        },
      },
      disabled: true
    })
    return { state }
  },
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkImageByLinkId();
    });
  },
  components: {
    quillEditor
  },
  data() {
    return {
      urlGuid: "",
      linkImage: new LinkImage(),
    };
  },
  methods: {
    closeWindow() {
      // Close the current window/tab
      window.close();
    },
    async getLinkImageByLinkId() {
      if (this.urlGuid) {
        await getLinkImageByLinkId(this.urlGuid)
          .then((data) => {
            this.linkImage = data;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    handleImageError() {
      // default blank image
      this.linkImage.url =
        "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=";
    },
  },
};
</script>

<style scoped>
.map-container-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Optional: Center vertically on the whole viewport height */
}

.map_container {
  position: relative;
  display: inline-block;
}

.map_container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.ql-container.ql-snow {
  border: none !important;
}
</style>
