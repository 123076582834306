<template>
  <div class="">
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form
          @submit.prevent="handleSubmit"
          class="w-full px-4 py-4 bg-white rounded-md shadow-md"
        >
          <label
            for="number"
            class="block font-semibold text-left call-gray-700"
          >
          {{$t("type-links.enter-your-number")}}:
          </label>
          <div class="flex items-center mb-4 ">
            <input
              type="text"
              id="number"
              :maxlength="100"
              placeholder=""
              v-model="linkCall.phoneNumber"
              @input="validateCall"
              class="w-full md:w-1/2 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkCall } from "@/models/linkCall-model";
import {
  getLinkCallByLinkId,
  saveLinkCall,
} from "@/services/linkCallService.js";

export default {
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-call-form-valid", false);
  },
  props: {
    linkId: String,
  },
  emits: ["is-call-form-valid", "link-call"],
  data() {
    return {
      linkCall: new LinkCall(),
    };
  },
  methods: {
    async getLinkCallByLinkId() {
      if (this.linkId) {
        await getLinkCallByLinkId(this.linkId)
          .then((data) => {
            this.linkCall = data;
            this.validateCall();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkCall(linkId) {
      this.linkCall.linkId = linkId;

      await saveLinkCall(this.linkCall)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    validateCall() {
      this.linkCall.phoneNumber = this.linkCall.phoneNumber.replace(
        /[^0-9]/g,
        ""
      );

      if (this.linkCall.phoneNumber?.toString().length > 0) {
        this.$emit("is-call-form-valid", true);
      } else {
        this.$emit("is-call-form-valid", false);
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkCallByLinkId();
      },
    },
    linkCall: {
      handler(updatedLinkCall) {
        this.$emit("link-call", updatedLinkCall);
      },
      deep: true,
    },
  },
};
</script>

<style>
#number::-webkit-inner-spin-button,
#number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
