<template>
  <div :class="themeColorClass" class="h-screen">
    <button @click="closeWindow()" class="z-10 absolute text-gray-500 top-2 right-2 hover:text-gray-700">
      <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
    <quill-editor :style="{ 'background-color': linkText.backgroundColor }" class="h-fill " v-model:value="linkText.text" :options="state.editorOption"
      :disabled="state.disabled" />

  </div>
</template>

<script>
import { reactive } from 'vue'
import { quillEditor } from 'vue3-quill'
import { LinkText } from "@/models/linkText-model";
import { getLinkTextByLinkId } from "@/services/linkTextService.js";

export default {
  setup() {
    const state = reactive({
      content: '',
      _content: '',
      editorOption: {
        placeholder: '',
        modules: {
          toolbar: false

        },
      },
      disabled: true
    })
    return { state }
  },
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkTextByLinkId();
    });
  },
  mounted() {
  },
  components: {
    quillEditor
  },
  data() {
    return {
      urlGuid: "",
      linkText: new LinkText(),
    };
  },
  methods: {
    closeWindow() {
      window.close();
    },
    async getLinkTextByLinkId() {
      if (this.urlGuid) {
        await getLinkTextByLinkId(this.urlGuid)
          .then((data) => {
            this.linkText = data;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
  },
  computed: {
    themeColorClass() {
      switch (this.linkText.themeId) {
        case 1:
          return 'bg-theme1';
        case 2:
          return 'bg-theme2';
        case 3:
          return 'bg-theme3';
        case 4:
          return 'bg-theme4';
        case 5:
          return 'bg-theme5';
        case 6:
          return 'bg-theme6';
        default:
          return 'bg-theme0';
      }
    },
  }
};
</script>
<style>
.bg-theme0 {
  background-image: none;
}

.bg-theme1 {
  .ql-editor {
    background-image: url("@/assets/img/textures/mapTexture.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme2 {
  .ql-editor {
    background-image: url("@/assets/img/textures/blueTexture.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme3 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Hearts.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme4 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Leaves.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme5 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Paper1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.bg-theme6 {
  .ql-editor {
    background-image: url("@/assets/img/textures/Paper2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
