import { createRouter, createWebHistory } from "vue-router";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import LinkGridList from "./components/qr/links/LinkGridList.vue";
import LinkDetail from "./components/qr/links/LinkDetail.vue";
import GroupGridList from "./components/qr/groups/GroupGridList.vue";
import GroupDetail from "./components/qr/groups/GroupDetail.vue";
import DashBoard from "./components/qr/DashBoard.vue";
import LoginView from "./components/shared/LoginView.vue";
import LandingView from "./components/landing/LandingView.vue";
import DynamicLinks from "./components/landing/components/DynamicLinks.vue";
import MessagesForDiverseAudiences from "./components/landing/components/MessagesForDiverseAudiences.vue";
import InstantTrackingAndMonitoring from "./components/landing/components/InstantTrackingAndMonitoring.vue";
import HotspotDescription from "./components/landing/components/HotSpots.vue";
import GamesDescription from "./components/landing/components/GamesDescription.vue";
import AugmentedLinks from "./components/landing/components/AugmentedLinks.vue";
import RegisterUser from "./components/shared/RegisterUser.vue";
import Faq from "./components/qr/FaqView.vue";
import Pricing from "./components/qr/PricingView.vue";
import GamesMain from "./components/gaming/GamesMain.vue";
import GameDetail from "./components/gaming/GameDetail.vue";
import PrintLink from "./components/qr/PrintLink.vue";
import Analytics from "./components/qr/AnalyticsView.vue";
//Redirection
import RedirectLink from "./redirection/RedirectLink.vue";
import RedirectHotSpot from "./redirection/RedirectHotSpot.vue";
import RedirectText from "./redirection/RedirectText.vue";
import RedirectEmail from "./redirection/RedirectEmail.vue";
import RedirectCall from "./redirection/RedirectCall.vue";
import RedirectImage from "./redirection/RedirectImage.vue";
import RedirectSms from "./redirection/RedirectSms.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "dashboard",
      path: "/dashboard",
      component: DashBoard,
      meta: { requiresAuth: true },
    },
    {
      name: "analytics",
      path: "/analytics",
      component: Analytics,
      meta: { requiresAuth: true },
    },
    {
      name: "faq",
      path: "/faq",
      component: Faq,
      meta: { requiresAuth: false },
    },
    {
      name: "pricing",
      path: "/pricing",
      component: Pricing,
      meta: { requiresAuth: false },
    },
    {
      name: "links",
      path: "/links",
      component: LinkGridList,
      meta: { requiresAuth: true },
    },
    {
      name: "link-detail",
      path: "/link-detail/:id:typeId?:typeName?",
      component: LinkDetail,
      meta: { requiresAuth: true },
    },
    {
      name: "groups",
      path: "/groups",
      component: GroupGridList,
      meta: { requiresAuth: true },
    },
    {
      name: "group-detail",
      path: "/group-detail/:id",
      component: GroupDetail,
      meta: { requiresAuth: true },
    },
    { name: "login", path: "/login", component: LoginView },
    {
      name: "dynamic-links-description",
      path: "/dynamic-links-description",
      component: DynamicLinks,
    },
    {
      name: "messages-for-diverse-audiences-description",
      path: "/messages-for-diverse-audiences-description",
      component: MessagesForDiverseAudiences,
    },
    {
      name: "instant-tracking-and-monitoring-description",
      path: "/instant-tracking-and-monitoring-description",
      component: InstantTrackingAndMonitoring,
    },
    {
      name: "hotspots-description",
      path: "/hotspots-description",
      component: HotspotDescription,
    },
    {
      name: "games-description",
      path: "/games-description",
      component: GamesDescription,
    },
    {
      name: "augmented-links-description",
      path: "/augmented-links-description",
      component: AugmentedLinks,
    },
    {
      name: "landing",
      path: "/landing",
      component: LandingView,
    },
    { name: "registerUser", path: "/registerUser", component: RegisterUser },
    {
      path: "/gamesMain",
      name: "gamesMain",
      component: GamesMain,
      meta: { requiresAuth: true },
    },
    {
      path: "/game-detail/:id",
      name: "game-detail",
      component: GameDetail,
      meta: { requiresAuth: true },
    },

    // **** Redirection ***
    { path: "/redirect/:urlGuid", name: "redirect", component: RedirectLink },
    {
      path: "/redirectHotSpot/:urlGuid",
      name: "redirectHotSpot",
      component: RedirectHotSpot,
    },
    {
      path: "/redirectImage/:urlGuid",
      name: "redirectImage",
      component: RedirectImage,
    },
    {
      path: "/redirectText/:urlGuid",
      name: "redirectText",
      component: RedirectText,
    },
    {
      path: "/redirectEmail/:urlGuid",
      name: "redirectEmail",
      component: RedirectEmail,
    },
    {
      path: "/redirectCall/:urlGuid",
      name: "redirectCall",
      component: RedirectCall,
    },
    {
      path: "/redirectSms/:urlGuid",
      name: "redirectSms",
      component: RedirectSms,
    },

    { path: "/print-link/:urlGuid", component: PrintLink },
    // Default route
    { path: "/", redirect: "/landing" },
  ],
});

router.beforeEach(async (to, from, next) => {
  const user = firebase.auth().currentUser;

  if (to.matched.some((record) => record.meta.requiresAuth) && !user) {
    // User is not logged in, redirect to login page
    next({ name: "landing" });
  } else {
    next(); // Continue with navigation
  }
});

export default router;
