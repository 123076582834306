<template>
    <div class="px-4 py-16 font-semibold text-center text-gray-800">
      <div class="mt-4 w-full p-4 mx-auto bg-gray-100">
        <form
          @submit.prevent="handleSubmit"
          class="w-full px-2 py-8 bg-white rounded-md shadow-md"
        >
          <h2 class="mb-8 text-3xl font-semibold">
            {{ $t("landing.games-title") }}
          </h2>
  
          <!-- Row 1: Description on the Left, Image on the Right -->
          <div class="mb-8 sm:flex sm:items-center">
            <div class="sm:w-1/2 sm:ml-4">
              <p class="text-lg leading-relaxed">
                {{ $t("landing.games.paragraph1") }}
              </p>
            </div>
  
            <!-- Second image shown on desktop, hidden on mobile -->
            <div class="w-full sm:w-1/2">
              <img
                src="@/assets/img/landing/1.jpg"
                class="w-2/3 mx-auto h-auto object-cover rounded-lg"
              />
            </div>
          </div>
  
          <!-- Row 2: Image on the Left, Description on the Right -->
          <!-- We also hide/show image before and after paragraph depending on mobile/desktop -->
          <div class="mb-8 sm:flex sm:items-center">
            <!-- hide on mobile-show on desktop -->
            <div class="hidden mb-4 sm:w-1/2 sm:mr-4 sm:block">
              <img
                src="@/assets/img/landing/2.jpg"
                class="w-2/3 mx-auto h-auto object-cover rounded-lg"
              />
            </div>
            <div class="sm:w-1/2">
              <p class="text-lg leading-relaxed">
                {{ $t("landing.games.paragraph2") }}
              </p>
            </div>
            <!-- hide on desktop -show on mobile -->
            <div class="block mb-4 sm:w-1/2 sm:mr-4 sm:hidden">
              <img
                src="@/assets/img/landing/2.jpg"
                class="w-2/3 mx-auto h-auto object-cover rounded-lg"
              />
            </div>
          </div>
  
          <!-- Row 3: Description on the Left, Image on the Right -->
          <div class="sm:flex sm:items-center">
            <div class="sm:w-1/2 sm:ml-4">
              <p class="text-lg leading-relaxed">
                {{ $t("landing.games.paragraph3") }}
              </p>
            </div>
            <div class="w-full sm:w-1/2">
              <img
                src="@/assets/img/landing/3.jpg"
                class="w-2/3 mx-auto h-auto object-cover rounded-lg"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    created() {},
    data() {
      return {};
    },
    methods: {},
  };
  </script>
  