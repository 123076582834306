<template>
  <div>
    <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
      <div class="flex items-center justify-between py-4">
        <h2 class="text-3xl font-semibold">{{ $t("common.group") }}</h2>
        <div class="flex justify-end mb-2 space-x-2 md:mt-0">
          <button @click="save()" type="button" :disabled="!formValid" :class="{
            'px-4 py-2 font-semibold text-white bg-lynx-dark-blue rounded': true,
            'sm:hover:bg-lynx-light-blue': formValid,
            'opacity-50 cursor-not-allowed': !formValid,
          }">
            {{ $t("common.save") }}
          </button>
          <button @click="$router.go(-1)" type="button"
            class="px-4 py-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue sm:hover:bg-lynx-light-blue hover:text-white hover:border-transparent">
            {{ $t("common.back") }}
          </button>
        </div>
      </div>

      <div class="w-full p-4 mx-auto bg-gray-100">
        <form @submit.prevent="handleSubmit" class="w-full px-2 py-8 bg-white rounded-md shadow-md">
          <div class="mb-4">
            <label for="name" class="block mb-2 font-semibold text-left text-gray-700">
              {{ $t("common.name") }}: <span class="text-red-500">*</span>
            </label>
            <input type="text" id="name" v-model="group.name" :maxlength="100"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
          </div>

          <div class="mb-4">
            <label for="description" class="block mb-2 font-semibold text-left text-gray-700">
              {{ $t("common.description") }}:
            </label>
            <textarea id="description" :maxlength="2048" v-model="group.description"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
              rows="4"></textarea>
          </div>
        </form>
      </div>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import { Group } from "@/models/group-model";
import { getGroupById, saveGroup } from "@/services/groupService.js";

export default {
  components: { PageSpinner },
  created() {
    this.group.id = this.$route.params.id;
    this.getDetailById();
  },
  data() {
    return {
      showSpinner: false,
      group: new Group(),
      formValid: false,
    };
  },
  methods: {
    async getDetailById() {
      if (this.group.id === "new") {
        this.group.id = null;
        return;
      }

      this.showSpinner = true;
      await getGroupById(this.group.id)
        .then((data) => {
          this.group = data;
          this.group.id = this.$route.params.id;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async save() {
      this.showSpinner = true;
      await saveGroup(this.group)
        .then(() => {
          this.$toast.success(this.$t("groups.group-saved"), {
            position: "top-right",
          });
          this.$router.go(-1);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    validateForm(formData) {
      //Validate the components we interested in
      this.formValid = !!formData.name;
    },
  },
  watch: {
    group: {
      handler(newValue) {
        this.validateForm(newValue);
      },
      deep: true,
    },
  },
};
</script>
