<template>
  <div>Sending sms...</div>
</template>

<script>
import { LinkSms } from "@/models/linkSms-model";
import { getLinkSmsByLinkId } from "@/services/linkSmsService.js";

export default {
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkSmsByLinkId();
    });
  },
  data() {
    return {
      urlGuid: "",
      linkSms: new LinkSms(),
    };
  },
  methods: {
    async getLinkSmsByLinkId() {
      if (this.urlGuid) {
        await getLinkSmsByLinkId(this.urlGuid)
          .then((data) => {
            this.linkSms = data;

            this.initiatePhoneSms();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    initiatePhoneSms() {
      const phoneNumber = this.linkSms.phoneNumber;
      const message = this.linkSms.text;
      const smsUri = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
      window.location.href = smsUri;
    },
  },
};
</script>
