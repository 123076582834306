<template>
  <div class="">
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form
          @submit.prevent="handleSubmit"
          class="w-full px-4 py-4 bg-white rounded-md shadow-md"
        >
          <!-- Email -->
          <div class="mb-4 md:flex md:space-x-4">
            <div class="md:w-1/2">
              <label
                for="email"
                class="block font-semibold text-left text-gray-700"
              >
                {{ $t("common.email") }}:
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@example.com"
                :maxlength="255"
                v-model="linkEmail.email"
                @input="validateEmail"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
              />
              <p v-if="!isValidEmail" class="text-red-500 text-left">
                {{ $t("type-links.invalid-email-address") }}
              </p>
            </div>

            <!-- Subject -->
            <div class="md:w-1/2 md:flex-col">
              <label
                for="subject"
                class="block font-semibold text-left text-gray-700"
              >
                {{ $t("common.subject") }}:
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder=""
                :maxlength="255"
                v-model="linkEmail.subject"
                class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
              />
            </div>
          </div>

          <!-- Text -->
          <div class="mb-4">
            <label
              for="text"
              class="block font-semibold text-left text-gray-700"
            >
              {{ $t("common.body") }}:
            </label>
            <textarea
              id="text"
              name="text"
              placeholder=""
              :maxlength="2048"
              v-model="linkEmail.text"
              class="w-full px-4 py-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-lynx-light-blue"
              rows="4"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkEmail } from "@/models/linkEmail-model";
import {
  getLinkEmailByLinkId,
  saveLinkEmail,
} from "@/services/linkEmailService.js";
export default {
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-email-form-valid", false);
  },
  props: {
    linkId: String,
  },
  emits: ["is-email-form-valid", "link-email"],
  data() {
    return {
      linkEmail: new LinkEmail(),
      isValidEmail: false,
    };
  },
  methods: {
    async getLinkEmailByLinkId() {
      if (this.linkId) {
        await getLinkEmailByLinkId(this.linkId)
          .then((data) => {
            this.linkEmail = data;
            this.validateEmail();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkEmail(linkId) {
      this.linkEmail.linkId = linkId;
      await saveLinkEmail(this.linkEmail)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    validateEmail() {
      // Regular expression for a simple email validation
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailPattern.test(this.linkEmail.email)) {
        this.isValidEmail = true;
        this.$emit("is-email-form-valid", true);
      } else {
        this.isValidEmail = false;
        this.$emit("is-email-form-valid", false);
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkEmailByLinkId();
      },
    },
    linkEmail: {
      handler(updatedLinkEmail) {
        this.$emit("link-email", updatedLinkEmail);
      },
      deep: true,
    },
  },
};
</script>
