<template>
  <div class="">
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form @submit.prevent="handleSubmit" class="w-full px-4 py-4 bg-white rounded-md shadow-md">
          <label for="url" class="block font-semibold text-left text-gray-700">
            {{ $t("type-links.enter-your-url") }}:
          </label>
          <div class="flex items-center mb-4">
            <input type="text" id="url" :maxlength="2048" placeholder="https://" v-model="url"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
              @input="validateURL" />


          </div>
          <p v-if="!isValidURL" class="text-red-500 text-left">
            {{ $t("type-links.please-enter-a-valid-url") }}:
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // Emit false when the child component is mounted
    this.validateURL();
  },
  props: {
    initialUrl: String,
  },
  emits: ["is-url-form-valid", "url-change"],
  data() {
    return {
      url: this.initialUrl,
      isValidURL: true,
    };
  },
  methods: {
    validateURL() {
      try {
        new URL(this.url);
        this.isValidURL = true;
        this.$emit("is-url-form-valid", true);
        this.$emit("url-change", this.url);
      } catch (error) {
        this.isValidURL = false;
        this.$emit("is-url-form-valid", false);
        this.$emit("url-change", "");
      }
    },
  },
};
</script>
