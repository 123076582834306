export class Game {
  constructor(
    id = "",
    name = "",
    description = "",
    customerUid = "",
    groupId = "",
    typeCode = "",
    typeId = "",
    published = false,
    price = 0,
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.customerUid = customerUid;
    this.published = published;
    this.price = price;
    this.groupId = groupId;
    this.typeCode = typeCode;
    this.typeId = typeId;
    this.created = created;
    this.updated = updated;
  }
}
