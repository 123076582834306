<template>
  <div class="flex items-center justify-between">
    <h2 class="text-3xl font-semibold">{{ $t("common.printing") }}</h2>
    <div class="space-x-5">
      <button
        @click="$router.go(-1)"
        type="button"
        class="px-4 py-2 font-semibold text-gray-700 bg-transparent border border-lynx-dark-blue rounded hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
      >
        {{ $t("common.back") }}
      </button>
    </div>
  </div>

  <div class="flex h-full my-4 bg-gray-100">
    <div class="flex flex-col justify-center w-2/3 items-left">
      <div ref="printContent">
        <!-- Content you want to print goes here -->
        <p>{{ $t("printing.content-to-print") }}</p>
        <qrcode-vue
          class="object-cover object-center w-full h-full m-2"
          :value="urlGuid"
          :size="qrSize"
          level="L"
        ></qrcode-vue>
      </div>
      <div class="mt-2 text-left">
        <button
          type="button"
          @click="print"
          class="px-4 py-2 font-semibold text-gray-700 bg-transparent border border-lynx-dark-blue rounded hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
        >
          {{ $t("common.print") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  components: {
    QrcodeVue,
  },
  created() {
    this.urlGuid = this.$route.params.urlGuid;
  },
  data() {
    return {
      qrSize: 300,
      urlGuid: "",
    };
  },
  methods: {
    print() {
      // Get the DOM element you want to print
      // const printContent = this.$refs.printContent;

      // Open the print dialog
      window.print();
    },
  },
};
</script>
