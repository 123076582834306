<template>
  <div>
    <div class="px-4 py-8 pt-16 font-semibold text-center text-gray-800">
      <div class="flex items-center justify-between py-4">
        <h2 class="text-3xl font-semibold">{{ $t("common.game") }}</h2>
        <div class="flex justify-end mb-2 space-x-2 md:mt-0">
          <button @click="save()" type="button" :disabled="!formValid" :class="{
            'px-4 py-2 font-semibold text-white bg-lynx-dark-blue rounded': true,
            'sm:hover:bg-lynx-light-blue': formValid,
            'opacity-50 cursor-not-allowed': !formValid,
          }">
            {{ $t("common.save") }}
          </button>
          <button @click="$router.go(-1)" type="button"
            class="px-4 py-2 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue sm:hover:bg-lynx-light-blue hover:text-white hover:border-transparent">
            {{ $t("common.back") }}
          </button>
        </div>
      </div>

      <table-scroll :screenMaxHeight="210">
        <div class="mx-2 mt-2">
          <div class="flex items-center justify-between bg-white banner">
            <div class="">
              <!-- Tab A -->
              <button @click="setActiveTab('A')" :class="{ 'bg-lynx-light-blue  pt-4': activeTab === 'A' }"
                class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.detail") }}
              </button>

              <!-- Tab B -->
              <button :disabled="!game.id" @click="setActiveTab('B')" :class="{
                'bg-lynx-light-blue pt-4': activeTab === 'B',
                'opacity-50 cursor-not-allowed': !game.id,
              }" class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.customize") }}
              </button>

              <!-- Tab C -->
              <button :disabled="!game.id" @click="setActiveTab('C')" :class="{
                'bg-lynx-light-blue pt-4': activeTab === 'C',
                'opacity-50 cursor-not-allowed': !game.id,
              }" class="flex-1 p-2 mr-1 bg-gray-100 border-2 rounded-md border-black-400">
                {{ $t("common.analytics") }}
              </button>
            </div>
          </div>

          <div v-show="activeTab === 'A'" class="flex flex-col p-4 bg-gray-100 md:flex-row">
            <form @submit.prevent="handleSubmit" class="w-full px-2 py-8 bg-white rounded-md shadow-md">
              <div class="mb-4">
                <label for="name" class="block mb-2 font-semibold text-left text-gray-700">
                  {{ $t("common.name") }}: <span class="text-red-500">*</span>
                </label>
                <input type="text" id="name" v-model="game.name" :maxlength="100"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
              </div>

              <div class="mb-4">
                <label for="description" class="block mb-2 font-semibold text-left text-gray-700">
                  {{ $t("common.description") }}:
                </label>
                <textarea id="description" :maxlength="2048" v-model="game.description"
                  class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                  rows="4"></textarea>

                <div class="sm:flex">
                  <!-- First Column -->
                  <div class="sm:flex-1 sm:mr-4">
                    <div class="mb-2">
                      <label for="type" class="block mb-2 font-semibold text-left text-gray-700">{{ $t("common.publish")
                      }}:
                      </label>
                      <div class="flex items-center cursor-pointer" @click="toggleSwitch">
                        <div class="mr-3"></div>
                        <div class="relative">
                          <div class="w-10 h-4 mt-1 bg-gray-400 rounded-full shadow-inner toggle__line"></div>
                          <div class="absolute inset-y-0 left-0 w-6 h-6 rounded-full shadow toggle__dot bg-blue" :class="{
                            'bg-blue-500 inset-y-0 left-5': game.published,
                            'bg-gray-300 inset-y-0 left-0': !game.published,
                          }"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Second Column -->
                  <div class="sm:flex-1">
                    <div class="mb-2">
                      <label for="price" class="block mb-2 font-semibold text-left text-gray-700">{{ $t("common.price")
                      }}:
                      </label>
                      <input type="text" id="price" placeholder="0.00" v-model="game.price" 
                        @input="validatePrice"
                        class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="sm:flex">
                <!-- First Column -->
                <div class="sm:flex-1 sm:mr-4">
                  <div class="mb-2">
                    <label for="type" class="block mb-2 font-semibold text-left text-gray-700">{{ $t("common.type") }}:
                      <span class="text-red-500">*</span></label>
                    <select :disabled="game.id" id="type" v-model="game.typeId"
                      class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
                      :class="{
                        'bg-gray-200 text-gray-500 cursor-not-allowed': game.id,
                      }">
                      <option v-for="option in gameTypeOptions" :key="option.id" :value="option.id">
                        {{ $t("gaming." + option.code) }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Second Column -->
                <div class="sm:flex-1">
                  <div class="mb-2">
                    <label for="group" class="block mb-2 font-semibold text-left text-gray-700">{{ $t("common.group") }}:
                      <span class="text-red-500">*</span></label>
                    <select id="group" v-model="game.groupId"
                      class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue">
                      <option v-for="option in groupOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div v-show="activeTab === 'B'" class="flex flex-col p-4 bg-gray-100 md:flex-row">
            <scavenger-hunt :gameId="game.id" ref="scavengerHuntRef"></scavenger-hunt>
          </div>

          <div v-show="activeTab === 'C'" class="flex flex-col p-4 bg-gray-100 md:flex-row">
            Todo
          </div>
        </div>
      </table-scroll>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import { Game } from "@/models/game-model";
import { getGameById, saveGame, getGameTypes } from "@/services/gameService.js";
import { getGroups } from "@/services/groupService.js";
import dialogService from "@/services/dialogService";
import ScavengerHunt from "@/components/gaming/components/ScavengerHunt.vue";
import TableScroll from "../shared/TableScroll.vue";

export default {
  created() {
    this.game.id = this.$route.params.id;
    this.getGameTypes();
    this.getGroups();
    this.getDetailById();
  },
  components: {
    ScavengerHunt,
    TableScroll,
    PageSpinner,
  },
  provide() {
    return {
      saveParent: this.save,
    };
  },
  data() {
    return {
      showSpinner: false,
      activeTab: "A", // Default active tab
      game: new Game(),
      formValid: false,
      saveParentFromChild: false,
      gameTypeOptions: [],
      groupOptions: [],
    };
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    toggleSwitch() {
      this.game.published = !this.game.published;
      if (this.game.published) {
        this.$toast.success(this.$t("gaming.published"), {
          position: "top-right",
        });
      } else {
        this.$toast.success(this.$t("gaming.unpublished"), {
          position: "top-right",
        });
      }
    },
    setLinkTypeCode(newTypeId) {
      this.game.typeCode = this.gameTypeOptions.find(
        (x) => x.id == newTypeId
      ).code;
    },
    validatePrice() {
      // Assuming this.game.price is the input for the price
      let price = this.game.price;

      // Remove non-numeric and non-dot characters
      price = price.replace(/[^0-9.]/g, "");

      // Split the string by the decimal point
      const parts = price.split(".");

      // Allow only one decimal point
      if (parts.length > 2) {
        // More than one decimal point, keep only the first part (integer part)
        price = parts[0] + "." + parts.slice(1).join("");
      }

      this.game.price = price;
    },
    async getDetailById() {
      if (this.game.id === "new") {
        this.game.id = null;
        return;
      }

      this.showSpinner = true;
      await getGameById(this.game.id)
        .then((data) => {
          this.game = data;
          this.setLinkTypeCode(this.game.typeId);
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async getGroups() {
      this.showSpinner = true;
      await getGroups()
        .then((data) => {
          this.groupOptions = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(async () => {
          this.showSpinner = false;
          if (this.groupOptions.length == 0) {
            await dialogService.open({
              okOnly: true,
              title: this.$t("groups.no-groups-exist"),
              message: this.$t("groups.please-add-at-least-one-group"),
            });

            this.$router.push("/groups");
          }
        });
    },
    async getGameTypes() {
      this.showSpinner = true;
      await getGameTypes()
        .then((data) => {
          this.gameTypeOptions = data;
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    async save() {
      this.showSpinner = true;
      await saveGame(this.game)
        .then(async (data) => {
          switch (this.game.typeCode) {
            case "scavengerHunt":
              var scavengerHuntRefComponent = this.$refs.scavengerHuntRef;
              await scavengerHuntRefComponent.saveScavengerHunt(data.id);
              break;
          }
          this.$toast.success(this.$t("gaming.game-saved"), {
            position: "top-right",
          });

          this.game.id = data.id;
          this.getDetailById();
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },

    validateForm(formData) {
      //Validate the components we interested in
      const requiredFields = ["name", "groupId", "typeId"];

      this.formValid = requiredFields.every((field) => !!formData[field]);
    },
  },
  watch: {
    "game.typeId"(newTypeId) {
      this.setLinkTypeCode(newTypeId);
    },
    game: {
      handler(newValue) {
        this.validateForm(newValue);
      },
      deep: true,
    },
  },
};
</script>
