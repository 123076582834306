<template>
  <div>
    <!-- Backdrop -->
    <div
      v-if="isVisible"
      class="fixed inset-0 z-40 overflow-hidden bg-black opacity-50"
    ></div>

    <!-- Dialog -->
    <div
      v-if="isVisible"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <div class="relative p-8 bg-white rounded-md shadow-lg">
        <!-- Close Icon -->
        <button
          @click="handleClose"
          class="absolute text-gray-500 top-2 right-2 hover:text-gray-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <!-- Popup Content -->
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onClose: Function, // This is the function to resolve the promise
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    handleClose() {
      this.onClose(false); // Resolve the promise with false
      this.isVisible = false;
    },
  },
};
</script>
