//todo we had to use the compatability version
// import firebase from "firebase/app";
// import "firebase/auth";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyABx__RcRchMKvs2Qq-FXykHjdCPBlxREw",
  authDomain: "link-manager-3e3b4.firebaseapp.com",
  projectId: "link-manager-3e3b4",
  storageBucket: "link-manager-3e3b4.appspot.com",
  messagingSenderId: "325578901880",
  appId: "1:325578901880:web:be164ec3c3542b6396e08d",
};



// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();


export default firebase;
