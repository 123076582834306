<template>
  <div>
    <div class="px-4 py-2  font-semibold text-center text-gray-800">
      <div v-if="linkExpired" class="flex items-center justify-between py-4">
        <div class="flex justify-end mb-2 space-x-2  ">
          <button @click="closeWindow()" class="z-10 absolute text-gray-500 top-2 right-2 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 w-8 h-8" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <p>{{ $t("common.link-expired") }}</p>
      </div>

      <div>
        <p v-if="!linkExpired">{{ $t("common.redirecting") }}</p>
      </div>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>




<script>
import { getTypeById } from "@/services/typeService";
import { getLinkByUrlGuid, saveLink } from "@/services/linkService.js";

export default {
  data() {
    return {
      linkExpired: false,
      linkText: "",
      link: {},
      urlGuid: "",
      type: {},
    };
  },
  beforeRouteEnter(to, from, next) {
    // Access the encodedUrl parameter from the route
    const urlGuid = to.params.urlGuid;
    next((vm) => {
      // Now vm (component instance) is available
      vm.urlGuid = urlGuid;
      vm.getLinkByUrlGuid();
    });
  },
  methods: {
    closeWindow() {
      window.close();
    },
    async getTypeById(id) {
      await getTypeById(id)
        .then((data) => {
          this.type = data;
          this.redirectByType();
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    async getLinkByUrlGuid() {
      await getLinkByUrlGuid(this.urlGuid)
        .then((data) => {
          this.link = data;

          //check if qr code is valid by date
          if (this.link.dateRangeActive) {
            var todaysDate = new Date().toISOString().split("T")[0];
            if (
              todaysDate < this.link.startDate ||
              todaysDate > this.link.endDate
            ) {
              this.linkExpired = true;
            }
          }

          if (!this.linkExpired) {
            this.UpdateLink(data);
            this.getTypeById(data.typeId);
          }
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    async UpdateLink(link) {
      link.scanned = link.scanned + 1;
      await saveLink(link);
    },
    setEmail() {
      this.$router.push("/redirectEmail/" + this.link.id);
    },
    setSms() {
      this.$router.push("/redirectSms/" + this.link.id);
    },
    setCall() {
      this.$router.push("/redirectCall/" + this.link.id);
    },
    setText() {
      this.$router.push("/redirectText/" + this.link.id);
    },
    setHotSpot() {
      this.$router.push("/redirectHotSpot/" + this.link.id);
    },
    setImage() {
      this.$router.push("/redirectImage/" + this.link.id);
    },
    redirectByType() {
      switch (this.type.code) {
        case "link":
          window.location.href = this.link.url;
          break;
        case "call":
          this.setCall();
          break;
        case "email":
          this.setEmail();
          break;
        case "sms":
          this.setSms();
          break;
        case "text":
          this.setText();
          break;
        case "hotSpot":
          this.setHotSpot();
          break;
        case "image":
          this.setImage();
          break;
      }
    },
  },
};
</script>
