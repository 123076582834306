import "firebase/compat/firestore";
import { firestore } from "../components/firebase";

const db = firestore;

export const getLinkTextByLinkId = async (linkId) => {
  const querySnapshot = await db.collection("link_text").where("linkId", "==", linkId).get();

  if (!querySnapshot.empty) {
    // Since there could be multiple matching documents, you might want to handle them accordingly.
    // For now, just return the first matching document.
    const docSnapshot = querySnapshot.docs[0];
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link Text not found");
  }
};

export const saveLinkText = async (data) => {
  const linkTextCollection = db.collection("link_text");

  if (data.id) {
    // Update existing link_Text
    await linkTextCollection.doc(data.id).update({
      linkId: data.linkId,
      text: data.text,
      themeId: data.themeId,
      backgroundColor: data.backgroundColor,
      updated: new Date().toISOString(),
    });
  } else {
    // Create new link_text
    await linkTextCollection.add({
      linkId: data.linkId,
      text: data.text,
      themeId: data.themeId,
      backgroundColor: data.backgroundColor,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteLinkTextById = async (id) => {
  const docRef = db.collection("link_text").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Link Text not found");
  }
};
