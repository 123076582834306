import "firebase/compat/firestore";
import { firestore, auth } from "../components/firebase";

const db = firestore;

export const getGroups = async () => {
  const querySnapshot = await db
    .collection("groups")
    .where("customerUid", "==", auth.currentUser.uid)
    .get();

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getGroupById = async (id) => {
  const docRef = db.collection("groups").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Group not found");
  }
};

export const saveGroup = async (data) => {
  const groupsCollection = db.collection("groups");

  if (data.id) {
    // Update existing group
    await groupsCollection.doc(data.id).update({
      name: data.name,
      description: data.description,
      customerUid: auth.currentUser.uid,
      updated: new Date().toISOString(),
    });
  } else {
    // Create new group
    await groupsCollection.add({
      name: data.name,
      description: data.description,
      customerUid: auth.currentUser.uid,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteGroupById = async (id) => {
  const docRef = db.collection("groups").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Group not found");
  }
};

export const groupIdIsUsedByLink = async (id) => {
  // Check if the group is referenced in any links
  const linksSnapshot = await db
    .collection("links")
    .where("groupId", "==", id)
    .where("customerUid", "==", auth.currentUser.uid)
    .get();

  if (!linksSnapshot.empty) {
    return true;
  }
  return false;
};

export const groupIdIsUsedByGame = async (id) => {
  // Check if the group is referenced in any games
  const gamesSnapshot = await db
    .collection("games")
    .where("groupId", "==", id)
    .where("customerUid", "==", auth.currentUser.uid)
    .get();

  if (!gamesSnapshot.empty) {
    return true;
  }
  return false;
};
