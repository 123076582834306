import "firebase/compat/firestore";
import { firestore, auth } from "../components/firebase";

const db = firestore;
const qrLynxUrl = "https://link-manager-3e3b4.web.app/";

export const getLinks = async () => {
  const querySnapshot = await db
    .collection("links")
    .where("customerUid", "==", auth.currentUser.uid)
    .get();

  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

export const getLinkById = async (id) => {
  const docRef = db.collection("links").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link not found");
  }
};

export const getLinkByUrlGuid = async (urlGuid) => {
  const querySnapshot = await db
    .collection("links")
    .where("urlGuid", "==", urlGuid)
    .get();

  if (!querySnapshot.empty) {
    const docSnapshot = querySnapshot.docs[0];
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link not found");
  }
};

export const saveLink = async (data) => {
  const linksCollection = db.collection("links");

  if (data.id) {
    // Update existing link
    await linksCollection.doc(data.id).update({
      name: data.name,
      groupId: data.groupId,
      typeId: data.typeId,
      type: data.type,
      url: data.url,
      externalUrl: `${qrLynxUrl}redirect/` + data.urlGuid,
      text: data.text,
      dateRangeActive: data.dateRangeActive,
      startDate: data.startDate,
      endDate: data.endDate,
      updated: new Date().toISOString(),
      scanned: data.scanned,
    });
    return data;
  } else {
    // Create new group
    let guid = crypto.randomUUID();
    const response = await linksCollection.add({
      name: data.name,
      groupId: data.groupId,
      typeId: data.typeId,
      type: data.type,
      url: data.url,
      externalUrl: `${qrLynxUrl}redirect/` + guid,
      urlGuid: guid,
      text: data.text,
      customerUid: auth.currentUser.uid,
      dateRangeActive: data.dateRangeActive,
      startDate: data.startDate,
      endDate: data.endDate,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
      scanned: data.scanned,
    });

    // Get the newly created document's ID
    const newLinkId = response.id;

    // Get the newly created document's data
    const newLinkSnapshot = await response.get();
    const newLinkData = newLinkSnapshot.data();

    // Add the ID to the data model
    newLinkData.id = newLinkId;

    // Return the data model with ID included
    return newLinkData;
  }
};

export const linkIdIsUsedByHotSpot = async (id) => {
  let linkIdBeingUsed = false;
  try {
    // Fetch all documents in the collection
    const querySnapshot = await db
      .collection("link_hot_spot")
      .where("customerUid", "==", auth.currentUser.uid)
      .get();

    // Iterate through each document in the collection
    querySnapshot.forEach((docSnapshot) => {
      // Check if the document exists
      if (docSnapshot.exists) {
        // Access the "hotspots" array
        const hotspots = docSnapshot.data().hotspots;

        if (hotspots) {
          // Use Array.prototype.some() to check if the ID exists in the array
          const idExists = hotspots.some((hotspot) => hotspot.linkId === id);

          if (idExists) {
            console.log(`ID ${id} exists1 in document ${docSnapshot.id}.`);
            linkIdBeingUsed = true;
            return;
          } else {
            console.log(
              `ID ${id} does not exist2 in document ${docSnapshot.id}.`
            );
            linkIdBeingUsed = false;
            return;
          }
        }
      } else {
        console.log(`Document ${docSnapshot.id} does not exist3.`);
        linkIdBeingUsed = false;
        return;
      }
    });
  } catch (error) {
    console.error("Error checking ID:", error);
  }
  return linkIdBeingUsed;
};

export const linkIdIsUsedByScavengerHunt = async (id) => {
  let linkIdBeingUsed = false;
  try {
    // Fetch all documents in the collection
    const querySnapshot = await db
      .collection("game_scavenger_hunt")
      .where("customerUid", "==", auth.currentUser.uid)
      .get();

    // Iterate through each document in the collection
    querySnapshot.forEach((docSnapshot) => {
      // Check if the document exists
      if (docSnapshot.exists) {
        // Access the "hotspots" array
        const linkIds = docSnapshot.data().linkIds;

        //Check string if it contain id and set our boolean if found
        linkIdBeingUsed = linkIds.includes(id);
      } else {
        console.log(`Document ${docSnapshot.id} does not exist3.`);
        linkIdBeingUsed = false;
        return;
      }
    });
  } catch (error) {
    console.error("Error checking ID:", error);
  }
  return linkIdBeingUsed;
};

export const deleteLinkById = async (id) => {
  const batch = db.batch(); // Create a batch to perform multiple operations

  // Delete documents in the subcollections (e.g., link_text, link_email, link_call)
  const subcollectionNames = [
    "link_text",
    "link_email",
    "link_call",
    "link_sms",
    "link_hot_spot",
  ];

  for (const subcollectionName of subcollectionNames) {
    // Query the subcollection where linkId matches the provided id
    const subcollectionRef = db
      .collection(subcollectionName)
      .where("linkId", "==", id);
    const querySnapshot = await subcollectionRef.get();

    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref); // Delete each document in the subcollection
    });
  }

  // Delete the main document in the "links" collection
  const mainDocRef = db.collection("links").doc(id);
  const mainDocSnapshot = await mainDocRef.get();

  if (mainDocSnapshot.exists) {
    batch.delete(mainDocRef); // Delete the main document
  } else {
    throw new Error("Link not found");
  }

  // Commit the batch to execute all delete operations
  await batch.commit();

  return true; // Deleted successfully
};
