<template>
  <div class="px-2">
    <div class="text-gray-800 bg-gray-200">
      <div class="flex py-2 ml-2 md:flex-row">
        <div class="relative mr-4">
          {{ $t("common.select-language") }}:

          <select v-model="$i18n.locale" @change="handleLocaleChange">
            <option
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :value="lang.code"
            >
              {{ lang.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {},
  props: {},
  emits: ["close-dialog"],
  data() {
    return {
      langs: [
        { code: "en", text: "English" },
        { code: "de", text: "Deutsch" },
        { code: "fr", text: "French" },
      ],
    };
  },
  methods: {
    handleLocaleChange() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped></style>
