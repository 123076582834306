<template>
  <div class=""> 
    <div class="flex flex-col py-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <form
          @submit.prevent="handleSubmit"
          class="w-full px-4 py-4 bg-white rounded-md shadow-md"
        >
          <label
            for="number"
            class="block font-semibold text-left sms-gray-700"
          >
          {{$t("type-links.enter-your-number")}}:
          </label>
          <div class="flex items-center mb-4">
            <input
              type="text"
              id="number"
              :maxlength="100"
              placeholder=""
              v-model="linkSms.phoneNumber"
              @input="validateSms"
              class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-lynx-light-blue"
            />
          </div>
          <!-- Text -->
          <div class="mb-4">
            <label
              for="text"
              class="block font-semibold text-left text-gray-700"
            >
              {{ $t("common.body") }}:
            </label>
            <textarea
              id="text"
              name="text"
              placeholder=""
              :maxlength="2048"
              v-model="linkSms.text"
              class="w-full px-4 py-2 border border-gray-300 rounded-md resize-none focus:outline-none focus:border-lynx-light-blue"
              rows="4"
            ></textarea>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { LinkSms } from "@/models/linkSms-model";
import {
  getLinkSmsByLinkId,
  saveLinkSms,
} from "@/services/linkSmsService.js";

export default {
  mounted() {
    // Emit false when the child component is mounted
    this.$emit("is-sms-form-valid", false);
  },
  props: {
    linkId: String,
  },
  emits: ["is-sms-form-valid", "link-sms"],
  data() {
    return {
      linkSms: new LinkSms(),
    };
  },
  methods: {
    async getLinkSmsByLinkId() {
      if (this.linkId) {
        await getLinkSmsByLinkId(this.linkId)
          .then((data) => {
            this.linkSms = data;
            this.validateSms();
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkSms(linkId) {
      this.linkSms.linkId = linkId;

      await saveLinkSms(this.linkSms)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    validateSms() {
      this.linkSms.phoneNumber = this.linkSms.phoneNumber.replace(
        /[^0-9]/g,
        ""
      );

      if (this.linkSms.phoneNumber?.toString().length > 0) {
        this.$emit("is-sms-form-valid", true);
      } else {
        this.$emit("is-sms-form-valid", false);
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is smsed immediately when the component is created
      handler() {
        this.getLinkSmsByLinkId();
      },
    },
    linkSms: {
      handler(updatedLinkSms) {
        this.$emit("link-sms", updatedLinkSms);
      },
      deep: true,
    },
  },
};
</script>

<style>
#number::-webkit-inner-spin-button,
#number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
</style>
