export class BarCodeStyle {
  constructor(
    background = "",
    foreground = "",
    level = "", //string('L' | 'M' | 'Q' | 'H')
    margin = 0,
    render = "", //Type: string('canvas' | 'svg')
    logoUrl = "",
    borderColor = "",
    header = "",
    footer = "",
    borderStyle = "",
    borderWidth = "",
    id = "",
    linkId = "",
    created = new Date().toISOString(),
    updated = new Date().toISOString()
  ) {
    this.id = id;
    this.linkId = linkId;
    this.background = background;
    this.foreground = foreground;
    this.level = level;
    this.margin = margin;
    this.render = render;
    this.logoUrl = logoUrl;
    this.borderColor = borderColor;
    this.header = header;
    this.footer = footer;
    this.borderStyle = borderStyle;
    this.borderWidth = borderWidth;
    this.created = created;
    this.updated = updated;
  }
}
