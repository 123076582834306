import "firebase/compat/firestore";
import { firestore, auth } from "../components/firebase";

const db = firestore;

export const getLinkHotSpotByLinkId = async (linkId) => {
  const querySnapshot = await db
    .collection("link_hot_spot")
    .where("linkId", "==", linkId)
    .get();

  if (!querySnapshot.empty) {
    // Since there could be multiple matching documents, you might want to handle them accordingly.
    // For now, just return the first matching document.
    const docSnapshot = querySnapshot.docs[0];
    return {
      id: docSnapshot.id,
      ...docSnapshot.data(),
    };
  } else {
    throw new Error("Link HotSpot not found");
  }
};

export const saveLinkHotSpot = async (data) => {
  const linkHotSpotCollection = db.collection("link_hot_spot");

  if (data.id) {
    // Update existing link_HotSpot
    await linkHotSpotCollection.doc(data.id).update({
      linkId: data.linkId,
      url: data.url,
      hotspots: data.hotspots,
      backgroundColor: data.backgroundColor,
      text: data.text,
      customerUid: auth.currentUser.uid,
      updated: new Date().toISOString(),
    });
  } else {
    // Create new link_hot_spot
    await linkHotSpotCollection.add({
      linkId: data.linkId,
      url: data.url,
      hotspots: data.hotspots,
      backgroundColor: data.backgroundColor,
      text: data.text,
      customerUid: auth.currentUser.uid,
      created: new Date().toISOString(),
      updated: new Date().toISOString(),
    });
  }
  return true; // Saved successfully
};

export const deleteLinkHotSpotById = async (id) => {
  const docRef = db.collection("link_hot_spot").doc(id);
  const docSnapshot = await docRef.get();

  if (docSnapshot.exists) {
    await docRef.delete();
    return true; // Deleted successfully
  } else {
    throw new Error("Link HotSpot not found");
  }
};
