<template>
  <div class="mx-2 mb-2">
    <div class="flex flex-col p-4 bg-gray-100 md:flex-row">
      <div class="w-full mx-auto">
        <div class="items-center justify-center w-full p-2 bg-white rounded-md shadow-md">
          <!-- QRCODE WITH HEADER AND FOOTER -->
          <div class="flex flex-col items-center sm:items-start">
            <div class="mb-2 overflow-hidden" :style="{ width: calculateQrSize() + 'px' }">
              <input v-model="barcodeStyle.header" type="text" class="w-full text-center border rounded"
                :placeholder="$t('barcode.header-placeholder')" />
            </div>
            <div class="qrcode">
              <qrcode-vue class="object-cover object-center w-full h-full" :class="barcodeStyle.borderStyle" :style="{
                'border-width': barcodeStyle.borderWidth,
                'border-color': barcodeStyle.borderColor,
              }" :value="externalUrl" :size="qrSize" :level="barcodeStyle.level" :background="barcodeStyle.background"
                :foreground="barcodeStyle.foreground" :margin="barcodeStyle.margin"
                :render-as="barcodeStyle.render"></qrcode-vue>
              <img v-if="barcodeStyle.logoUrl.length > 0" class="qrcode__image" :src="barcodeStyle.logoUrl" />
            </div>
            <div class="mb-2 overflow-hidden" :style="{ width: calculateQrSize() + 'px' }">
              <input v-model="barcodeStyle.footer" type="text" class="w-full text-center border rounded"
                :placeholder="$t('barcode.footer-placeholder')" />
            </div>
          </div>

          <div>
            <div class="flex bg-white banner">
              <div>
                <!-- Tab A -->
                <button @click="setActiveTab('A')" :class="{ 'bg-lynx-light-blue pt-3': activeTab === 'A' }"
                  class="flex-1 p-2 mr-1 text-sm bg-gray-100 border-2 rounded-md shadow-lg border-black-400 sm:text-md">
                  {{ $t("common.colours") }}
                </button>

                <!-- Tab B -->
                <button @click="setActiveTab('B')" :class="{ 'bg-lynx-light-blue pt-3': activeTab === 'B' }"
                  class="flex-1 p-2 mr-1 text-sm bg-gray-100 border-2 rounded-md shadow-lg border-black-400 sm:text-md">
                  {{ $t("common.borders") }}
                </button>

                <!-- Tab C -->
                <button @click="setActiveTab('C')" :class="{ 'bg-lynx-light-blue pt-3': activeTab === 'C' }"
                  class="flex-1 p-2 mr-1 text-sm bg-gray-100 border-2 rounded-md shadow-lg border-black-400 sm:text-md">
                  {{ $t("common.logo") }}
                </button>

                <!-- Tab D -->
                <button @click="setActiveTab('D')" :class="{ 'bg-lynx-light-blue pt-3': activeTab === 'D' }"
                  class="flex-1 p-2 text-sm bg-gray-100 border-2 rounded-md shadow-lg border-black-400 sm:text-md">
                  {{ $t("common.quality") }}
                </button>
              </div>
            </div>

            <!-- Content -->
            <div class="w-full p-4 bg-gray-100 sm:w-2/3">
              <!-- Content based on activeTab -->
              <div v-show="activeTab === 'A'">
                <!-- Content for Tab A -->
                <div class="flex flex-row items-center sm:items-start">
                  <!-- Column 1 -->
                  <div class="flex-1">
                    <!-- Content for Column 1 -->
                    <div class="mb-2 mr-4">
                      <label class="block text-sm font-bold text-gray-700  " for="background">
                        {{ $t("common.background") }}:</label>
                      <input v-model="barcodeStyle.background" type="color" class="rounded cursor-pointer" :class="{
                        backgroundColor: barcodeStyle.background,
                        width: '20px',
                        height: '20px',
                      }" id="background" />
                    </div>
                  </div>

                  <!-- Column 2 -->
                  <div class="flex-1">
                    <!-- Content for Column 2 -->
                    <div class="mb-2 mr-4">
                      <label class="block text-sm font-bold text-gray-700  " for="foreground">
                        {{ $t("common.foreground") }}:</label>
                      <input v-model="barcodeStyle.foreground" type="color" class="rounded cursor-pointer" :class="{
                        backgroundColor: barcodeStyle.foreground,
                        width: '20px',
                        height: '20px',
                      }" id="foreground" />
                    </div>
                  </div>

                  <!-- Column 3 -->
                  <div class="flex-1">
                    <!-- Content for Column 3 -->
                    <div class="mb-2 mr-4">
                      <label class="block text-sm font-bold text-gray-700  " for="borderColor">
                        {{ $t("common.border") }}:</label>

                      <div class="mb-2">
                        <input v-model="barcodeStyle.borderColor" type="color" class="rounded cursor-pointer" :class="{
                          backgroundColor: barcodeStyle.borderColor,
                          width: '20px',
                          height: '20px',
                        }" id="borderColor" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="activeTab === 'B'">
                <!-- Content for Tab B -->
                <div class="max-w-sm mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="style">
                    {{ $t("common.border-style") }}:</label>
                  <select v-model="barcodeStyle.borderStyle" class="w-full p-2 border rounded" id="level">
                    <option value="border-none">
                      {{ $t("common.none") }}
                    </option>
                    <option value="border-solid">
                      {{ $t("common.solid") }}
                    </option>
                    <option value="border-dashed">
                      {{ $t("common.dashed") }}
                    </option>
                    <option value="border-dotted">
                      {{ $t("common.dotted") }}
                    </option>
                    <option value="border-double">
                      {{ $t("common.double") }}
                    </option>
                  </select>
                </div>

                <div class="max-w-sm mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="margin">{{ $t("common.border-width")
                  }}:</label>
                  <select v-model="barcodeStyle.borderWidth" class="w-full p-2 border rounded" id="level">
                    <option value="0px">{{ $t("common.none") }}</option>
                    <option value="2px">2</option>
                    <option value="4px">4</option>
                    <option value="6px">6</option>
                    <option value="8px">8</option>
                    <option value="10px">10</option>
                    <option value="12px">12</option>
                    <option value="14px">14</option>
                    <option value="16px">16</option>
                    <option value="18px">18</option>
                    <option value="20px">20</option>
                  </select>
                </div>
                <div class="max-w-sm mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="margin">{{ $t("common.margin")
                  }}:</label>
                  <select v-model="barcodeStyle.margin" class="w-full p-2 border rounded" id="level">
                    <option value="0">{{ $t("common.none") }}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div v-show="activeTab === 'C'">
                <!-- Content for Tab C -->
                <div class="max-w-md mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="logoUrl">{{ $t("common.logo")
                  }}:</label>

                  <input v-model="barcodeStyle.logoUrl" type="text" class="w-full p-2 border rounded" id="logoUrl" />
                </div>
              </div>
              <div v-show="activeTab === 'D'">
                <!-- Content for Tab D -->
                <div class="max-w-sm mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="level">{{
                    $t("barcode.error-correction-level") }}:</label>
                  <select v-model="barcodeStyle.level" class="w-full p-2 border rounded" id="level">
                    <option value="L">{{ $t("common.low") }}</option>
                    <option value="M">{{ $t("common.medium") }}</option>
                    <option value="Q">{{ $t("common.quartile") }}</option>
                    <option value="H">{{ $t("common.high") }}</option>
                  </select>
                </div>

                <div class="max-w-sm mb-2">
                  <label class="block text-sm font-bold text-gray-700 text-left" for="render">{{ $t("barcode.render-type")
                  }}:</label>
                  <select v-model="barcodeStyle.render" class="w-full p-2 border rounded" id="render">
                    <option value="canvas">{{ $t("common.canvas") }}</option>
                    <option value="svg">{{ $t("common.svg") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="m-2 mt-4 text-left">
            <button type="button"
              class="px-4 py-2 mr-1 font-semibold text-gray-700 bg-transparent border rounded border-lynx-dark-blue hover:bg-lynx-light-blue hover:text-white hover:border-transparent"
              @click.stop="printLink()">
              {{ $t("common.print") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import { BarCodeStyle } from "@/models/barCodeStyle-model";
import { saveLinkBarCodeStyle, getLinkBarCodeStyleByLinkId } from "@/services/barCodeStyleService.js";
export default {
  components: {
    QrcodeVue,
  },
  props: {
    linkId: String,
    urlGuid: String,
    externalUrl: String,
  },
  data() {
    return {
      activeTab: "A", // Default active tab
      barcodeStyle: new BarCodeStyle(
        "#ffffff",
        "#000000",
        "L",
        0,
        "canvas",
        "https://logosandtypes.com/wp-content/uploads/2021/02/Commodore.png",
        "#8b0000",
        "", //header
        "", //footer
        "border-solid",
        "8px"
      ),

      qrSize: this.calculateQrSize(),
    };
  },
  methods: {
    async getLinkBarCodeStyleByLinkId() {
      if (this.linkId) {
        await getLinkBarCodeStyleByLinkId(this.linkId)
          .then((data) => {
            this.barcodeStyle = data;
          })
          .catch((error) => {
            this.$toast.error(error, { position: "top-right", duration: 0 });
          });
      }
    },
    async saveLinkBarCodeStyle(linkId) {
      this.barcodeStyle.linkId = linkId;

      await saveLinkBarCodeStyle(this.barcodeStyle)
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        });
    },
    printLink() {
      this.$router.push(`/print-link/:${this.urlGuid}`);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    calculateQrSize() {
      if (window.innerWidth < 640) {
        // Mobile
        return 300;
      } else if (window.innerWidth <= 768) {
        // Tablets
        return 300;
      } else {
        // Desktops
        return 300;
      }
    },
  },
  watch: {
    linkId: {
      immediate: true, // This ensures the handler is called immediately when the component is created
      handler() {
        this.getLinkBarCodeStyleByLinkId();
      },
    },
  },
  computed: {},
};
</script>

<style scoped>
.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 20%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
}
</style>
