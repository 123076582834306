<template>
  <div>
    <div class="  h-full px-4 py-8 pt-16 font-semibold text-center text-gray-800 md:py-12 lg:py-16">
      <div class="block sm:hidden">
        <p class="mt-6 text-2xl font-semibold">
          {{ $t("dashboard.title1") }}
        </p>
        <p class="mt-2 text-2xl font-semibold">
          {{ $t("dashboard.title2") }}
        </p>
      </div>

      <div class="flex">
        <div class="flex-1 hidden mr-2 sm:block">
          <p class="mt-6 text-4xl font-semibold">
            {{ $t("dashboard.title1") }}
          </p>

          <p class="mt-6 text-4xl font-semibold">
            {{ $t("dashboard.title2") }}
          </p>

          <p class="mt-10">{{ $t("dashboard.sub-title") }}</p>
          <img src="@/assets/img/dashboard.jpg" class="w-2/3 p-4 mx-auto mt-4 rounded-3xl" />
        </div>

        <div class="sm:w-3/5">
          <div class="flex flex-wrap sm:mt-12">
            <div v-for="option in typeOptions" :key="option" class="w-full sm:w-1/2">
              <div class="flex items-center">
                <div @click="handleSelectedType(option)"
                  class="flex items-center w-full h-20 px-4 py-2 mb-4 border-gray-300 rounded-md shadow-lg focus:outline-none focus:border-lynx-light-blue sm:hover:bg-lynx-light-blue">
                  <i class="mr-2 text-2xl fas text-lynx-light-green" :class="option.icon"></i>
                  <div class="flex-grow cursor-pointer">
                    <span class="block text-lg font-semibold text-center" :class="{ 'line-through': option.id === '' }">
                      {{ $t("types." + option.code) }}
                    </span>
                  </div>
                  <i @click.stop="showDescription(option)"
                    class="ml-2 cursor-pointer far fa-question-circle font-extralight"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <page-spinner :showSpinner="showSpinner"></page-spinner>
  </div>
</template>

<script>
import PageSpinner from "@/components/shared/PageSpinner.vue";
import { getTypes } from "@/services/typeService";
import dialogService from "@/services/dialogService";

export default {
  created() {
    this.getTypes();
  },
  components: { PageSpinner },
  data() {
    return {
      showSpinner: false,
      typeOptions: [],
      typesToDo: `[
    {
        "id": "",
        "icon": "far fa-id-card",
        "description": "",
        "code": "vcard",
        "name": "V-card"
    },
    {
        "id": "",
        "icon": "fas fa-wifi",
        "description": "",
        "code": "wifi",
        "name": "WI-FI"
    },
    {
        "id": "",
        "icon": "fa fa-calendar",
        "description": "",
        "code": "event",
        "name": "Event"
    },
    {
        "id": "",
        "icon": "fas fa-file-pdf",
        "description": "",
        "code": "pdf",
        "name": "PDF"
    },
    {
        "id": "",
        "icon": "fas fa-play-circle",
        "description": "",
        "code": "video",
        "name": "Video"
    },
    {
        "id": "",
        "icon": "fas fa-share-alt",
        "description": "",
        "code": "socialmedia",
        "name": "Social Media"
    }
]`,
    };
  },
  methods: {
    async getTypes() {
      this.showSpinner = true;
      await getTypes()
        .then((data) => {
          this.typeOptions = data;
          this.typeOptions = [
            ...this.typeOptions,
            ...JSON.parse(this.typesToDo),
          ];
        })
        .catch((error) => {
          this.$toast.error(error, { position: "top-right", duration: 0 });
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
    handleSelectedType(event) {
      this.$router.push({
        name: "link-detail",
        params: {
          id: "new",
          typeId: event.id,
          typeName: event.name,
        },
      });
    },
    async showDescription(type) {
      var msg = this.$t("types." + type.code + "-description");
      if (type.id == "") msg = this.$t("common.coming-soon");

      await dialogService.open({
        okOnly: true,
        title: this.$t("types." + type.code),
        message: msg,
      });
    },
  },
};
</script>

<style></style>
