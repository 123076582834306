const apiKey = "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";

export const generateScavengerHunt = async (numberOfLocations) => {
    const response = await fetch('https://api.openai.com/v1/completions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo-instruct',
        prompt: `Create a detailed scavenger hunt with ${numberOfLocations} locations for children. Include interesting tasks at each location. Split each description with a line feed.`,
        max_tokens: 400, // Increase this value based on your needs
      }),
    });
  
    const data = await response.json();
    console.log(data)
  
    // Extract information from the AI-generated response
    const locationsDetails = data.choices.map(choice => choice.text.trim());
  
    // Return a flat array with concatenated detailed descriptions
    const flatArray = locationsDetails
      .flatMap(details => details.split('\n').filter(item => item.trim() !== '').map(task => task.replace(/^\d+\.\s*/, '')))
      .filter(item => item.trim() !== '');
  
    return flatArray;
  };
  